import React, { useEffect, useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import { Button, Divider } from '@material-ui/core';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { sportEnumValues } from '@pitchbooking-dev/pb-shared/lib/helpers';
import imageSelector from '@pitchbooking-dev/pb-shared/lib/helpers/imageSelector';
import {
  convertToRaw, convertFromRaw, EditorState, ContentState,
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToMarkdown from 'draftjs-to-markdown';
import { markdownToDraft } from 'markdown-draft-js';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { Formik } from 'formik';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import TextField from '@material-ui/core/TextField';
import * as facilitiesActions from '../../../../reducers/facilitiesReducer';
import SimpleSelect from '../../../../components/SimpleSelect';
import SimpleMultipleSelect from '../../../../components/SimpleMultipleSelect';
import GoogleMapsAutoComplete from '../../../../components/GoogleMapsAutoComplete';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useOrigin, usePendingChanges, useCompany } from '../../../../hooks';

import '../../../../styles/facilities.css';

const descriptionTooltipString = 'This description will appear on the main booking page. It’s a good opportunity to tell your prospective bookers some more information about the facility.';
const AccessTooltipString = 'Email information will only appear in the booking confirmation email and is used to let the booker know about any instructions they will need to enter the facility.';
const smsAccessTooltipString = 'SMS information will only appear in the booking confirmation SMS and is used to let the booker know about any instructions they will need to enter the facility.';
const bookingInstructionsTooltip = 'This will appear as an alert message at the top of the facility page. It’s a good opportunity to let your bookers know about any special instructions they will need to know before booking.';

const DetailsConfiguration = () => {
  const dispatch = useDispatch();
  const pendingChanges = usePendingChanges();
  const facilities = useSelector((state) => state.facilities);
  const { selectedFacility } = facilities;
  const [editorState, setEditorState] = useState();
  const [markdown, setMarkdown] = useState(null);

  const { bookerUrl } = useOrigin();

  useEffect(() => {
    if (selectedFacility.description) {
      try {
        const contentState = convertFromRaw(JSON.parse(selectedFacility.description));
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
        setMarkdown(draftToMarkdown(convertToRaw(newEditorState.getCurrentContent())));
      } catch (err) {
        setEditorState(
          EditorState.createWithContent(
            ContentState.createFromText(selectedFacility.description),
          ),
        );
        setMarkdown(selectedFacility.description);
      }
    } else {
      setEditorState(EditorState.createEmpty());
      setMarkdown(null);
    }
  }, [selectedFacility.description]);

  const {
    accessInstructions,
    smsAccessInstructions,
    description,
    locationDescription,
    name,
    size,
    sport,
    setting,
    surface,
    timezone,
    VATReducible,
  } = selectedFacility;

  const sizeFields = ['FULL_SIZE', 'JUNIOR_SIZE', '5-A-SIDE', '6-A-SIDE', '7-A-SIDE', '8-A-SIDE', '9-A-SIDE', 'NOT_APPLICABLE'];
  const surfaceFields = ['3G', '4G', '5G', '6G', 'MUGA', 'GRASS', 'ASTROTURF', 'HARDCOURT', 'OUTDOOR_COURT', 'INDOOR_COURT', 'OTHER', 'NOT_APPLICABLE'];
  const settingFields = ['COVERED', 'INDOOR', 'OUTDOOR', 'OTHER', 'NOT_APPLICABLE'];
  const { products } = useCompany();
  const isSmsEnabled = products?.smsNotifications === 'ENABLED';

  const updateFacility = async (id, values) => {
    dispatch(facilitiesActions.requestSelectedFacilityUpdate(id, values));
    dispatch(sessionActions.updateSuccessfulSnackbar('Your info has been updated successfully!', true));
  };

  const resetDetailsForm = (resetForm) => {
    resetForm();
    if (markdown !== null) {
      const rawData = markdownToDraft(markdown);
      const contentState = convertFromRaw(rawData);
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      setEditorState(EditorState.createEmpty());
    }
    dispatch(sessionActions.updateInfoSnackbar('Edit canceled facility info has not been changed!', true));
  };

  const bookingInstructions = React.useMemo(() => {
    if (!selectedFacility.bookingInstructions && !pendingChanges?.bookingInstructions) {
      return {
        editor: EditorState.createEmpty(),
        markdown: '',
      };
    }

    const contentState = pendingChanges?.bookingInstructions
      ? convertFromRaw(JSON.parse(pendingChanges?.bookingInstructions))
      : convertFromRaw(JSON.parse(selectedFacility.bookingInstructions));
    const newEditorState = EditorState.createWithContent(contentState);

    return {
      editor: newEditorState,
      markdown: draftToMarkdown(convertToRaw(newEditorState.getCurrentContent())),
    };
  }, [selectedFacility.bookingInstructions, pendingChanges?.bookingInstructions]);

  return (
    <>
      {/* If the user has pending changes */}
      {Object.keys(pendingChanges ?? {}).length > 0 && (
        <div style={{
          backgroundColor: '#efefef', padding: '1rem', borderRadius: '10px', marginBottom: '1rem',
        }}
        >
          <Typography variant="h5">Pending Changes</Typography>
          <Typography variant="body2">
            The changes you have made to this facility will not be visible to the public until your
            application to claim the facility has been approved.
          </Typography>
        </div>
      )}

      <Formik
        initialValues={{
          accessInstructions: pendingChanges?.accessInstructions || accessInstructions || '',
          smsAccessInstructions: pendingChanges?.smsAccessInstructions || smsAccessInstructions || '',
          // costCode: pendingChanges?.costCode || costCode || '',
          description: pendingChanges?.description || description || '',
          locationDescription: pendingChanges?.locationDescription || locationDescription || '',
          name: pendingChanges?.name || name || '',
          size: pendingChanges?.size || size || '',
          sport: pendingChanges?.sport || sport || [],
          surface: pendingChanges?.surface || surface || '',
          setting: pendingChanges?.setting || setting || '',
          // taxExemptCostCode: pendingChanges?.taxExemptCostCode || taxExemptCostCode || '',
          timezone: pendingChanges?.timezone || timezone || '',
          VATReducible: pendingChanges?.VATReducible || VATReducible || '',
          bookingInstructions: bookingInstructions
            ? bookingInstructions.editor
            : '',
        }}
        enableReinitialize
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // Convert editor state to markdown
          const contentState = editorState.getCurrentContent();
          const rawContentState = JSON.stringify(convertToRaw(contentState));

          const bookingInstructionsState = values.bookingInstructions?.getCurrentContent();
          const bookingInstructions = bookingInstructionsState?.hasText()
            ? JSON.stringify(convertToRaw(bookingInstructionsState))
            : null;

          const form = {
            ...values,
            description: rawContentState,
            bookingInstructions,
          };

          setSubmitting(true);
          try {
            updateFacility(selectedFacility.id, form);
            resetForm();
          } catch (err) {
            dispatch(sessionActions.updateErrorSnackbar(
              'An error occurred while attempting to handle your request. Please try again, if the issues persists please contact us at info@pitchbooking.com.',
              true,
            ));
          }
        }}
      >
        {({
          values, errors, touched, handleSubmit, handleChange, resetForm, setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            onReset={resetForm}
            style={{
              display: 'flex', flexDirection: 'column',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div style={{
                display: 'flex', 'flex-direction': 'column', gap: '0.75rem',
              }}
              >
                <Typography variant="h6" style={{ marginBottom: '1rem' }}>
                  {`${selectedFacility?.type !== 'AMENITY' ? 'Facility' : 'Amenity'} details configuration`}
                </Typography>

                {/* Name */}
                <PBInput
                  type="text"
                  id="name"
                  label={`${selectedFacility?.type !== 'AMENITY' ? 'Facility' : 'Amenity'} Name`}
                  value={values.name}
                  onChange={handleChange}
                  isError={touched.name && Boolean(errors.name)}
                  errorMessage={touched.name && errors.name}
                />

                {/* Sport */}
                {selectedFacility?.type && selectedFacility.type !== 'AMENITY' && (
                <>
                  <SimpleMultipleSelect
                    propValue={values.sport}
                    arrayValue={sportEnumValues}
                    label="Sports"
                    onChange={(e) => setFieldValue('sport', e)}
                  />

                  {/* Surface */}
                  <SimpleSelect
                    propValue={values.surface}
                    arrayValue={surfaceFields}
                    label="Surface"
                    onChange={(e) => setFieldValue('surface', e)}
                  />

                  {/* Structure */}
                  <SimpleSelect
                    propValue={values.setting}
                    arrayValue={settingFields}
                    label="Indoor / Outdoor"
                    onChange={(e) => setFieldValue('setting', e)}
                  />

                  {/* Size */}
                  <SimpleSelect
                    propValue={values.size}
                    arrayValue={sizeFields}
                    onChange={(e) => setFieldValue('size', e)}
                    label="Size"
                  />

                  {/* Loaction */}
                  <GoogleMapsAutoComplete
                    locationDescription={locationDescription}
                    onChange={(newValue) => setFieldValue('locationDescription',
                      {
                        location: newValue.location,
                        locationDescription: newValue.locationDescription,
                        locationChanged: true,
                      })}
                  />

                  {/* Additional information */}
                  <Typography variant="h6">Additional information</Typography>
                  {/* Timezone */}
                  <ConditionallyVisible condition={timezone}>
                    <Typography variant="body2">Timezone</Typography>
                    <div style={{ margin: 5 }}>
                      {timezone}
                    </div>
                    <Divider />
                  </ConditionallyVisible>
                </>
                )}

                {/* VATReducible */}
                <ConditionallyVisible condition={VATReducible}>
                  <Typography variant="body2">VAT Reducible</Typography>
                  <div style={{ margin: 5 }}>
                    {VATReducible}
                  </div>
                  <Divider />
                </ConditionallyVisible>

                {/* Image */}
                <div className="facility-image-section">
                  <img
                    className="facility-image"
                    src={imageSelector(
                      pendingChanges?.photoURL ? pendingChanges : selectedFacility,
                    )}
                    alt="Facility"
                  />
                </div>
              </div>

              {selectedFacility?.type && selectedFacility.type !== 'AMENITY' && (
              <>
                <div style={{
                  display: 'flex',
                  width: '50vw',
                  flexDirection: 'column',
                  gap: '0.75rem',
                  margin: '0rem 0rem 0rem 3.5rem',
                }}
                >
                  <div>
                    <div style={{
                      display: ' flex',
                      alignItems: 'center',
                      padding: '1rem 0',
                    }}
                    >
                      <Typography variant="body2">Facility Description</Typography>
                      <Tooltip title={descriptionTooltipString} placement="right">
                        <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                      </Tooltip>
                    </div>

                    {/* About */}
                    <div style={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      padding: '6px 8px',
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    }}
                    >
                      <Typography variant="h7">Current About Text:</Typography>
                      <div style={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        padding: '6px 8px',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        backgroundColor: '#EBEBE4',
                        marginBottom: '1rem',
                      }}
                      >
                        <ReactMarkdown linkTarget="_blank">
                          {markdown}
                        </ReactMarkdown>
                      </div>
                      <Editor
                        editorState={editorState}
                        onEditorStateChange={(editorState) => setEditorState(editorState)}
                        toolbar={{
                          options: ['inline', 'blockType', 'list', 'link', 'history'],
                          inline: {
                            options: ['bold', 'italic'],
                          },
                          blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                        }}
                        placeholder="Enter your about description here..."
                        stripPastedStyles
                      />
                    </div>
                  </div>

                  <div>
                    <div style={{
                      display: ' flex',
                      alignItems: 'center',
                      padding: '1rem 0',
                    }}
                    >
                      <Typography variant="body2">Booking Instructions</Typography>
                      <Tooltip title={bookingInstructionsTooltip} placement="right">
                        <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                      </Tooltip>
                    </div>

                    {/* Booking Instructions */}
                    <div style={{
                      borderStyle: 'solid',
                      borderWidth: '1px',
                      borderRadius: '5px',
                      padding: '6px 8px',
                      borderColor: 'rgba(0, 0, 0, 0.23)',
                    }}
                    >
                      <Typography variant="h7">Current Booking Instructions Text:</Typography>
                      <div style={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '5px',
                        padding: '6px 8px',
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                        backgroundColor: '#EBEBE4',
                        marginBottom: '1rem',
                      }}
                      >
                        <ReactMarkdown linkTarget="_blank">
                          {bookingInstructions.markdown}
                        </ReactMarkdown>
                      </div>
                      <Editor
                        editorState={values.bookingInstructions}
                        onEditorStateChange={(e) => {
                          setFieldValue('bookingInstructions', e);
                        }}
                        toolbar={{
                          options: ['inline', 'blockType', 'list', 'link', 'history'],
                          inline: {
                            options: ['bold', 'italic'],
                          },
                          blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                          },
                        }}
                        placeholder="Enter your booking instructions here..."
                        stripPastedStyles
                      />
                    </div>
                  </div>

                  {/* Access Instructions */}
                  <div>
                    <div style={{ display: ' flex', alignItems: 'center' }}>
                      <Typography variant="body2">Booking email Information</Typography>
                      <Tooltip title={AccessTooltipString} placement="right">
                        <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                      </Tooltip>
                    </div>

                    <TextField
                      value={values.accessInstructions}
                      margin="normal"
                      multiline
                      type="textarea"
                      fullWidth
                      variant="outlined"
                      onChange={(e) => setFieldValue('accessInstructions', e.target.value)}
                      label="Access Instructions"
                    />
                  </div>

                  {/* SMS Access Instructions */}
                  <ConditionallyVisible condition={isSmsEnabled}>
                    <div>
                      <div style={{ display: ' flex', alignItems: 'center' }}>
                        <Typography variant="body2">Booking SMS Information</Typography>
                        <Tooltip title={smsAccessTooltipString} placement="right">
                          <HelpIcon style={{ color: '#333', marginLeft: '10px', width: '15px' }} />
                        </Tooltip>
                      </div>

                      <TextField
                        value={values.smsAccessInstructions}
                        margin="normal"
                        multiline
                        type="textarea"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setFieldValue('smsAccessInstructions', e.target.value)}
                        label="SMS Access Instructions"
                      />
                    </div>
                  </ConditionallyVisible>
                </div>
              </>
              )}
            </div>

            {/* From actions */}
            <div style={{
              display: 'flex', justifyContent: 'flex-start', marginTop: '3rem', gap: '1rem',
            }}
            >
              <Button variant="contained" color="primary" type="submit" style={{ width: '200px' }}>Save</Button>
              <Button variant="contained" onClick={() => resetDetailsForm(resetForm)} style={{ width: '200px' }}>Cancel</Button>

              {selectedFacility?.type && selectedFacility.type !== 'AMENITY' && (
                <a
                  href={`${bookerUrl}/book/facility/${selectedFacility.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button variant="contained" color="secondary" style={{ width: '200px' }}>View Booking Page</Button>
                </a>
              )}
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
function mapStateToProps(state) {
  const {
    facilities, companies, accessRestrictions,
  } = state;
  return {
    facilities,
    companies,
    accessRestrictions,
  };
}

const mapDispatchToProps = (dispatch) => ({
  updateSelectedFacilityStore: (id) => dispatch(facilitiesActions.updateSelectedFacilityStore(id)),
  requestSelectedFacilityUpdate: (facilityId, facilityData) => dispatch(
    facilitiesActions.requestSelectedFacilityUpdate(facilityId, facilityData),
  ),
  backToFacilities: () => dispatch(push('/facilities')),
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailsConfiguration);
