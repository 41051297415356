/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable, { } from 'material-table';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import '../../../styles/accessRestriction.css';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Search from '@material-ui/icons/Search';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Clear from '@material-ui/icons/Clear';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import fileDownload from 'js-file-download';
import JSON2CSV from 'json2csv';
import ToggleButtons from '../../../components/ToggleButtons';
import LoadingSkeletonDataTable from '../../../components/LoadingComponents/LoadingSkeletonDataTable';
import AccessRestrictionsDialog from './accessRestrictionDialog/AccessRestrictionsDialog';
import * as accessRestrictionsActions from '../../../reducers/accessRestrictionsReducer';
import DeleteDialog from './DeleteAccessRestrictionDialog';
import * as helper from '../../../helpers/index';
import ActionBar from '../../../components/ActionBar';
import PBButton from '../../../components/Button';
import ToolTip from '../../../components/Tooltip';
import DownloadPdf from '../../../components/DownloadPdf';
import FacilityChips from '../../../components/FacilityChips';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  paper: {
    width: '90%',
    margin: 'auto',
    backgroundColor: '#f8f9fb',
    marginBottom: theme.spacing(2),
    border: 'none',
    boxShadow: 'none',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ARDisplayButtonsData = [{
  buttonTitle: 'Current',
  buttonValue: 'CURRENT',
},
{
  buttonTitle: 'Expired',
  buttonValue: 'EXPIRED',
}];

const generateTimingString = (row) => {
  let timeFormatted = 'Always';
  if (row.recurrence !== 'ALWAYS') {
    timeFormatted = helper.createTimeRangeString(row.startTime, row.endTime);
  }
  return timeFormatted;
};

function AccessRestrictionsTable(props) {
  const classes = useStyles();
  const {
    accessRestrictions, facilities, companyMemberships,
    ARDisplay, updateCheck, ARtitle, isLoading, getOptions,
  } = props;

  accessRestrictions.forEach((row) => {
    if (row.recurrence === 'WEEKLY') {
      row.recurrenceText = `Every ${moment().weekday(row.weekday).format('dddd')}`;
    } else if (row.recurrence === 'DAILY') {
      row.recurrenceText = 'Daily';
    } else if (row.recurrence === 'NONE') {
      row.recurrenceText = 'One-off';
    } else if (row.recurrence === 'ALWAYS') {
      row.recurrenceText = '';
    } else {
      row.recurrenceText = row.recurrence;
    }
  });

  const handleEdit = (accessRestriction) => {
    const accessRestrictionToEdit = { ...accessRestriction };
    const { toggleEditing, enableAccessRestrictionEdit, updateAccessRestrictionDialog } = props;
    toggleEditing(true);

    accessRestrictionToEdit.startDateOption = 'YES';
    accessRestrictionToEdit.endDateOption = 'YES';

    if (!accessRestrictionToEdit.validFrom) {
      accessRestrictionToEdit.startDateOption = 'NO';
    }
    if (!accessRestrictionToEdit.validTo) {
      accessRestrictionToEdit.endDateOption = 'NO';
    }

    const { exclusions } = accessRestrictionToEdit;
    accessRestrictionToEdit.exclusionsEnabled = false;

    if (exclusions && exclusions.length > 0) {
      accessRestrictionToEdit.exclusions = exclusions.map((exclusion) => ({
        date: moment(exclusion.date).format('YYYY-MM-DD'),
        id: exclusion.id,
      }));
      accessRestrictionToEdit.exclusionsEnabled = true;
    }

    if (accessRestrictionToEdit.recurrence !== 'ALWAYS') {
      accessRestrictionToEdit.startTime = helper.createDateWithTime(
        moment(),
        accessRestrictionToEdit.startTime,
      ).startOf('minute').format();

      accessRestrictionToEdit.endTime = helper.createDateWithTime(
        moment(),
        accessRestrictionToEdit.endTime,
      ).endOf('minute').add(1, 'seconds').format();
    }

    if (accessRestrictionToEdit.weekday !== null) {
      accessRestrictionToEdit.weekday = accessRestrictionToEdit.weekday.toString();
    }

    enableAccessRestrictionEdit(accessRestrictionToEdit);
    updateAccessRestrictionDialog(true);
  };

  const closureTag = (<div className="closureTag">CLOSURE</div>);
  const subscriptionTag = (<div className="subscriptionTag">SUBSCRIPTION</div>);

  // Handle export for CSV and PDF
  const handleExport = (columns, data, fileType) => {
    let filterColumns = columns.map((column) => column.title);
    filterColumns = filterColumns.filter((item) => item !== 'Edit' && item !== 'Delete');

    const mappedData = data.map((row) => ({
      Description: row.description,
      Facilities: row.facilities.map((facility) => facility.name).join(', '),
      Recurrence: row.recurrenceText,
      Validity: helper.generateValidityText(row, row.facilities[0]?.timezone),
      Time: generateTimingString(row),
      Status: row.status,
      Access: row.type,
      Membership: row.memberships
        ? row.memberships.map((membership) => membership.name).join(', ')
        : '',
    }));

    if (fileType === 'csv') {
      const csvData = JSON2CSV({ data: mappedData, field: filterColumns, excelStrings: false });
      fileDownload(csvData, `Pitchbooking ${ARtitle}.csv`);
    } else {
      const colTitles = Object.keys(mappedData[0]);
      const rowData = mappedData.map((row) => Object.values(row));
      DownloadPdf(colTitles, rowData, `Pitchbooking ${ARtitle}.pdf`);
    }
  };

  return (
    <div className="table-section">
      <div className={classes.root}>
        <div style={{
          display: 'flex', flex: '1', justifyContent: 'space-between', paddingRight: '20px',
        }}
        >
          <ActionBar>
            <AccessRestrictionsDialog
              companyFacilities={facilities}
              companyMemberships={companyMemberships}
              ARtitle={ARtitle}
              getOptions={getOptions}
            />
            <div style={{ marginLeft: '100px' }}>
              <ToggleButtons
                buttonsData={ARDisplayButtonsData}
                changeOption={(option) => updateCheck(option)}
                value={ARDisplay}
                ARtitle={ARtitle}
              />
            </div>
          </ActionBar>
          <ToolTip contentfulName="priorityAccessTabToolTip" />
        </div>
        <AlternativelyVisible condition={isLoading}>
          <LoadingSkeletonDataTable />
          <Paper
            style={{
              margin: '10px', borderTopLeftRadius: '10px', borderTopRightRadius: '10px', overflowX: 'hidden', width: '100%',
            }}
            component={Paper}
          >
            <div style={{
              display: 'flex', flexWrap: 'none', marginLeft: '20px', marginTop: '40px', marginBottom: '30px',
            }}
            >
              <b>Key:</b>
              <div className="keys activeKey" />
              {' '}
              Active
              <div className="keys inactiveKey" />
              {' '}
              Inactive
            </div>
            <div className="access-restrictions-switch-section">
              <div className="facility-card-switch" />
            </div>
            <MaterialTable
              className={classes.table}
              data={accessRestrictions}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
              title={`Pitchbooking ${ARtitle}`}
              icons={{
                NextPage: () => <ChevronRight />,
                PreviousPage: () => <ChevronLeft />,
                Search: () => <Search />,
                Export: () => <SaveAlt />,
                ResetSearch: () => <Clear />,
                SortArrow: () => <UnfoldMoreIcon style={{ fill: '#4581E2' }} />,
                Filter: () => <Search />,
              }}
              columns={[
                {
                  title: 'Description',
                  field: 'description',
                },
                {
                  title: 'Facilities',
                  field: 'facilities',
                  render: (row) => (
                    <FacilityChips facilities={row.facilities} />

                  ),
                  customFilterAndSearch: (term, row) => {
                    const facilityName = row.facilities.map((facility) => facility.name);
                    const facilityNameString = facilityName.toString().toLowerCase();
                    return facilityNameString.includes(term.toLowerCase());
                  },
                },
                {
                  title: 'Recurrence',
                  field: 'recurrenceText',
                  filtering: false,
                  render: (row) => {
                    if (row.recurrenceText === 'One-off') {
                      return 'Continuous';
                    } if (row.recurrenceText === '') {
                      return 'Always';
                    }
                    return row.recurrenceText;
                  },
                },
                {
                  title: 'Validity',
                  field: 'validFrom',
                  filtering: false,
                  render: (row) => helper.generateValidityText(row, row.facilities[0]?.timezone),
                  customFilterAndSearch: (term, row) => {
                    const string = helper.generateValidityText(row,
                      row.facilities[0].timezone).toLowerCase();
                    return string.includes(term.toLowerCase());
                  },
                },
                {
                  title: 'Time',
                  field: 'startTime',
                  filtering: false,
                  render: (row) => generateTimingString(row),
                  customFilterAndSearch: (term, row) => {
                    const string = generateTimingString(row).toLowerCase();
                    return string.includes(term.toLowerCase());
                  },
                },
                {
                  title: 'Status',
                  field: 'status',
                  filtering: false,
                  render: (row) => {
                    const statusIcons = row.status === 'INACTIVE' ? (<div className="keys inactiveKey" style={{ marginLeft: '10px' }} />) : (<div className="keys activeKey" style={{ marginLeft: '10px' }} />);
                    return statusIcons;
                  },
                },
                {
                  title: 'Access',
                  field: 'type',
                  filtering: false,
                  render: (row) => {
                    let tagType;
                    const memberships = row.memberships.map((membership) => {
                      const { id } = membership;
                      const { membershipView } = props;
                      const accessList = (
                        <div
                          key={membership.name}
                          className="membershipsTag"
                          onClick={() => membershipView(id)}
                        >
                          {membership.name}
                        </div>
                      );
                      return accessList;
                    });
                    if (row.type === 'HOLIDAY') {
                      tagType = closureTag;
                    } else if (row.type === 'MEMBER_ACCESS') {
                      tagType = memberships;
                    } else if (row.type === 'SUBSCRIPTION') {
                      tagType = subscriptionTag;
                    }
                    return (tagType);
                  },
                },

                {
                  title: 'Edit',
                  field: 'edit',
                  filtering: false,
                  render: (row) => (
                    <div>
                      <PBButton
                        id="manager-edit-access-restrictions"
                        onClick={() => handleEdit(row)}
                        color="secondaryIcon"
                        buttonRole="editAccessRestrictions"
                      >
                        <EditIcon />
                      </PBButton>
                    </div>
                  ),
                },
                {
                  title: 'Delete',
                  field: 'delete',
                  filtering: false,
                  render: (row) => (
                    <DeleteDialog
                      // eslint-disable-next-line react/no-this-in-sfc
                      delete={() => this.deleteSubscription(row.id)}
                      name={ARtitle}
                      bodyTag={ARtitle}
                      type={row.type}
                      accessRestrictionId={row.id}
                      getOptions={getOptions}
                    />
                  ),
                },
              ]}
              options={{
                search: true,
                filtering: true,
                filterCellStyle: { width: 'auto' }, // Doens't seem to be added yet: https://github.com/mbrn/material-table/issues/522
                emptyRowsWhenPaging: false,
                tableLayout: 'auto',
                exportButton: true,
                exportAllData: false,
                exportCsv: (columns, data) => handleExport(columns, data, 'csv'),
                exportPdf: (columns, data) => handleExport(columns, data, 'pdf'),
                exportFileName: `Pitchbooking ${ARtitle}`,
                pageSize: 50,
                pageSizeOptions: [10, 25, 50, 100],
                showFirstLastPageButtons: false,
                // selection: true,
                showTitle: false,
                headerStyle: {
                  backgroundColor: '#efefef',
                  fontWeight: 'bold',
                },
              }}
              localization={{
                toolbar: {
                  exportTitle: 'Download',
                },
              }}
            />
          </Paper>
        </AlternativelyVisible>
      </div>
    </div>
  );
}

AccessRestrictionsTable.propTypes = {
  accessRestrictions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  companyMemberships: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  getOptions: PropTypes.shape().isRequired,
  updateAccessRestrictionDialog: PropTypes.func.isRequired,
  enableAccessRestrictionEdit: PropTypes.func.isRequired,
  updateCheck: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  ARDisplay: PropTypes.string.isRequired,
  ARtitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  membershipView: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  enableAccessRestrictionEdit: (accessRestriction) => dispatch(
    accessRestrictionsActions.enableAccessRestrictionEdit(accessRestriction),
  ),
  updateAccessRestrictionDialog: (keyWithValue) => dispatch(
    accessRestrictionsActions.updateAccessRestrictionDialog(keyWithValue),
  ),
  toggleEditing: (bool) => dispatch(accessRestrictionsActions.toggleEditing(bool)),
  membershipView: (id) => dispatch(push(`/memberships/${id}`)),
});

export default connect(null, mapDispatchToProps)(AccessRestrictionsTable);
