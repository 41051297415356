/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Typography, Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BreakdownCard } from './BreakdownCard';

export const SubNonSubBreakdownCards = ({
  filteredOrderData, currencySym,
}) => {
  const totalOrderAmount = filteredOrderData.reduce((acc, { total, refundTotal = 0 }) => acc + total - refundTotal, 0);
  // To track unique order IDs for subscriptions and non-subscriptions
  const uniqueSubsOrderIds = new Set();
  const uniqueNonSubsOrderIds = new Set();

  const { subsTotal, subsRefundTotal, subsCount } = filteredOrderData.reduce(
    (acc, { orders }) => {
      const subsOrders = orders.filter((order) => order.subscriptions?.some((subscription) => subscription.amount > 0 || subscription.refundAmount > 0) || (order.partial && order.reservations.length === 0));

      const subsData = subsOrders.reduce(
        (orderAcc, order) => {
          if (uniqueSubsOrderIds.has(order.id)) {
            // Skip this order if it has already been counted
            return orderAcc;
          }
          if (order.status === 'CREATED') {
            let subscriptionAmount = order.subscriptions
              ?.reduce((subAcc, subscription) => {
                const shmsoCount = subscription.shmso?.length || 1;
                return subAcc + ((order.partial ? order.total : subscription.amount) || 0) * shmsoCount;
              }, 0) || 0;
            if (order.reservations.length === 0 && order.partial && !uniqueSubsOrderIds.has(order.id)) {
              subscriptionAmount = order.total;
            }
            // Check if the order ID has already been counted
            if (!uniqueSubsOrderIds.has(order.id)) {
              uniqueSubsOrderIds.add(order.id);
              return {
                ...orderAcc,
                subsTotal: orderAcc.subsTotal + subscriptionAmount,
                subsCount: orderAcc.subsCount + 1, // Increment count for unique 'CREATED' orders
              };
            }

            return {
              ...orderAcc,
              subsTotal: orderAcc.subsTotal + subscriptionAmount,
            };
          } if (order.status === 'REFUNDED') {
            const subscriptionRefundAmount = order.subscriptions
              ?.reduce((subAcc, subscription) => {
                const shmsoCount = subscription.shmso?.length || 1;
                return subAcc + (subscription.refundAmount || 0) * shmsoCount;
              }, 0) || 0;
            return {
              ...orderAcc,
              subsRefundTotal: orderAcc.subsRefundTotal + subscriptionRefundAmount,
            };
          }
          return orderAcc;
        },
        { subsTotal: 0, subsRefundTotal: 0, subsCount: 0 },
      );

      return {
        subsTotal: acc.subsTotal + subsData.subsTotal,
        subsRefundTotal: acc.subsRefundTotal + subsData.subsRefundTotal,
        subsCount: acc.subsCount + subsData.subsCount,
      };
    },
    { subsTotal: 0, subsRefundTotal: 0, subsCount: 0 },
  );

  const { nonSubTotal, nonSubRefundTotal, nonSubCount } = filteredOrderData.reduce(
    (acc, { orders }) => {
      const nonSubsOrders = orders.filter((order) => order.reservations?.some((reservation) => reservation.total > 0) || (order.partial));

      const nonSubsData = nonSubsOrders.reduce(
        (orderAcc, order) => {
          if (uniqueNonSubsOrderIds.has(order.id)) {
            // Skip this order if it has already been counted
            return orderAcc;
          }
          if (order.status === 'CREATED') {
            let nonSubscriptionAmount = order.reservations
              ?.reduce((resAcc, reservation) => resAcc + ((order.partial ? (order.total / 100) : reservation.total) || 0), 0) || 0;
            if (!order.reservations && order.partial && !uniqueNonSubsOrderIds.has(order.id)) {
              nonSubscriptionAmount = order.total / 100;
            }
            // Check if the order ID has already been counted
            if (!uniqueNonSubsOrderIds.has(order.id)) {
              uniqueNonSubsOrderIds.add(order.id);
              return {
                ...orderAcc,
                nonSubTotal: orderAcc.nonSubTotal + (100 * nonSubscriptionAmount),
                nonSubCount: orderAcc.nonSubCount + 1, // Increment count for unique 'CREATED' orders
              };
            }

            return {
              ...orderAcc,
              nonSubTotal: orderAcc.nonSubTotal + (100 * nonSubscriptionAmount),
            };
          } if (order.status === 'REFUNDED') {
            const nonSubscriptionRefundAmount = order.reservations
              ?.reduce((resAcc, reservation) => {
                // Use partialRefundTotal if it's available
                const refundAmount = reservation.reservation_order?.partialRefundTotal !== null
                  ? reservation.reservation_order.partialRefundTotal
                  : reservation.total || 0;

                return resAcc + refundAmount;
              }, 0) || 0;

            return {
              ...orderAcc,
              nonSubRefundTotal: orderAcc.nonSubRefundTotal + 100 * nonSubscriptionRefundAmount,
              nonSubCount: orderAcc.nonSubCount + 1,
            };
          }
          return orderAcc;
        },
        { nonSubTotal: 0, nonSubRefundTotal: 0, nonSubCount: 0 },
      );

      return {
        nonSubTotal: acc.nonSubTotal + nonSubsData.nonSubTotal,
        nonSubRefundTotal: acc.nonSubRefundTotal + nonSubsData.nonSubRefundTotal,
        nonSubCount: acc.nonSubCount + nonSubsData.nonSubCount,
      };
    },
    { nonSubTotal: 0, nonSubRefundTotal: 0, nonSubCount: 0 },
  );

  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: '#F5F5F5',
        border: '1px solid #ddd',
        borderRadius: '0 0 0.75rem 0.75rem',
        margin: '0 2rem',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography style={{ fontWeight: '600' }}>Recurring / Non-Recurring Revenue Breakdown</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
            gap: '1rem',
          }}
        >
          {/* Subscription Total */}
          <BreakdownCard
            title="Recurring Total"
            count={subsCount}
            type="subscription"
            total={((subsTotal - subsRefundTotal) / 100).toFixed(2)} // Convert pence to pounds
            currencySym={currencySym}
            totalOrderAmount={totalOrderAmount / 100} // Convert pence to pounds
            componentType="subNonSub"
          />

          {/* Non-Subscription Total */}
          <BreakdownCard
            title="Non-Recurring Total"
            count={nonSubCount}
            type="nonSubscription"
            total={((nonSubTotal - nonSubRefundTotal) / 100).toFixed(2)} // Convert pence to pounds
            currencySym={currencySym}
            totalOrderAmount={totalOrderAmount / 100} // Convert pence to pounds
            componentType="subNonSub"
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
