import React, { useEffect } from 'react';
import { useReward } from 'react-rewards';
import { Typography, Button } from '@material-ui/core';

export const AgreementProcessing = () => {
  const { reward: confettiReward } = useReward('confettiReward', 'confetti');

  useEffect(() => {
    confettiReward();
  }, []);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '-webkit-fill-available',
      padding: '2rem',
      borderRadius: '0.75rem',
      border: '1px solid #e0e0e0',
      margin: '2.5rem',
      background: '#fff',
    }}
    >
      <span id="confettiReward" />
      <Typography style={{
        fontSize: '1.5rem',
        fontWeight: 500,
        color: 'rgb(75, 85, 99)',
        cursor: 'default',
      }}
      >
        {'🎉 Welcome to Pitchbooking! We\'re processing your agreement 🎉'}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '2rem', width: '25%' }}
        onClick={() => window.location.replace('/facilities')}
      >
        Done
      </Button>
    </div>
  );
};
