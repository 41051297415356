/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Card } from '@material-ui/core';

const testimonials = [
  { title: 'Ballymacash Sports Academy', url: 'https://www.youtube.com/embed/YTT3Qk5pxRo' },
  { title: 'World of Football Edinburgh', url: 'https://www.youtube.com/embed/g6ljmDZiK8A' },
  { title: 'Playball Stormont Belfast', url: 'https://www.youtube.com/embed/vkOgi8VllF8' },
];

export const Testimonials = ({ isMobile }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '-webkit-fill-available',
  }}
  >
    <Typography style={{ fontSize: '1.25rem', fontWeight: 800 }}>
      Case Studies
    </Typography>

    <div
      style={{
        display: 'grid',
        width: '100%',
        gap: '1.5rem',
        gridTemplateColumns: !isMobile ? 'repeat(3, 1fr)' : '1fr',
        gridAutoRows: 'minmax(150px, auto)',
      }}
    >
      {testimonials.map((testimonial) => (
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '1rem',
            borderRadius: '0.5rem',
            gap: '0.25rem',
          }}
        >
          <Typography style={{
            fontSize: '1rem', fontWeight: 600, textWrap: 'nowrap', marginBottom: '0.5rem',
          }}
          >
            {testimonial.title}
          </Typography>
          <iframe
            src={testimonial.url}
            loading="lazy"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Pitchbooking Explainer"
            className="w-full h-full"
            style={{
              borderRadius: '0.5rem',
              aspectRatio: '16 / 9',
              width: '-webkit-fill-available',
              border: 'none',
            }}
          />
        </Card>
      ))}
    </div>
  </div>
);
