/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useMutation } from '@tanstack/react-query';
import { updateCompanyDetailsService } from '../../../services/companiesServices';

const PRODUCT_TYPES = [
  { value: 'facility', label: 'Facilities' },
  { value: 'event', label: 'Events' },
  { value: 'league', label: 'Leagues' },
  { value: 'membership', label: 'Memberships' },
  { value: 'terminal', label: 'Terminal' },
  { value: 'bookingFee', label: 'Booking Fee' },
];

const commissionChangeSchema = Yup.object().shape({
  productType: Yup.string()
    .required('Product type is required')
    .oneOf(PRODUCT_TYPES.map((type) => type.value)),
  newCommissionRate: Yup.number()
    .required('New commission rate is required')
    .min(0, 'Must be at least 0')
    .max(100, 'Must be less than or equal to 100'),
});

const validationSchema = Yup.object().shape({
  fromDate: Yup.string()
    .required('Effective date is required'),
  alreadyNotified: Yup.boolean(),
  notificationDate: Yup.string()
    .when('alreadyNotified', {
      is: true,
      then: Yup.string().required('Notification date is required when already notified'),
      otherwise: Yup.string().nullable(),
    }),
  commissionChanges: Yup.array()
    .of(commissionChangeSchema)
    .min(1, 'At least one commission change is required'),
});

const getCurrentRate = (productType, companyInfo) => {
  const rateMap = {
    facility: companyInfo.commission,
    event: companyInfo.eventsCommission,
    league: companyInfo.leaguesCommission,
    membership: companyInfo.membershipCommission,
    terminal: companyInfo.terminalCommission,
    bookingFee: companyInfo.bookingFee,
  };
  return rateMap[productType] || 0;
};

const CommissionRateDialog = ({
  open, onClose, onSubmit, companyInfo,
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleClose = () => {
    setSelectedProducts([]);
    onClose();
  };
  const commissionMutation = useMutation({
    mutationFn: async (commissionChanges) => {
      const existingAccounting = companyInfo.accounting || {};
      const existingCommissionChanges = existingAccounting.commissionChanges || [];

      const accounting = {
        ...existingAccounting,
        commissionChanges: [
          ...existingCommissionChanges,
          ...commissionChanges,
        ].filter(Boolean),
      };

      const { data } = await updateCompanyDetailsService(companyInfo.id, { accounting });
      return data;
    },
    onSuccess: (data) => {
      onSubmit(data);
      handleClose();
    },
    onError: (error) => {
      console.error(error.message || 'Failed to schedule commission rates');
    },
  });

  const initialValues = {
    commissionChanges: [{
      productType: '',
      newCommissionRate: '',
    }],
    fromDate: moment().add(1, 'month').format('DD/MM/YYYY'),
    alreadyNotified: false,
    notificationDate: null,
  };

  const handleSubmit = (values) => {
    const commissionChanges = values.commissionChanges.map((change) => ({
      productType: change.productType,
      oldCommissionRate: getCurrentRate(change.productType, companyInfo),
      newCommissionRate: change.newCommissionRate,
      fromDate: moment(values.fromDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      notifiedOn: values.alreadyNotified
        ? moment(values.notificationDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
    }));

    commissionMutation.mutate(commissionChanges);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Schedule Commission Rate Changes
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values, setFieldValue, errors, handleChange, touched,
        }) => (
          <Form>
            <DialogContent style={{ marginTop: '-2rem' }}>
              <Typography variant="body2" color="textSecondary">
                When entering a new commission please enter a percentage rather
                than a decimal number for example for 10% enter 10.
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FieldArray name="commissionChanges">
                    {({ push, remove }) => (
                      <div>
                        {values.commissionChanges.map((_, index) => {
                          const availableProducts = PRODUCT_TYPES.filter(
                            (type) => !selectedProducts.includes(type.value)
                              || values.commissionChanges[index].productType === type.value,
                          );

                          return (
                            <Grid container spacing={2} key={index}>
                              <Grid item xs={12}>
                                <Divider style={{ margin: '1rem 0' }} />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <PBInput
                                  id={`commissionChanges.${index}.productType`}
                                  label="Product Type"
                                  type="select"
                                  options={availableProducts}
                                  fullWidth
                                  value={values.commissionChanges[index].productType}
                                  onChange={(e) => {
                                    const oldValue = values.commissionChanges[index].productType;
                                    const newValue = e.target.value;

                                    if (newValue) {
                                      const currentRate = getCurrentRate(newValue, companyInfo);
                                      const formattedRate = newValue === 'bookingFee'
                                        ? currentRate
                                        : (currentRate * 100);
                                      setFieldValue(`commissionChanges.${index}.newCommissionRate`, formattedRate);
                                    }

                                    setSelectedProducts((prev) => {
                                      const updated = prev.filter((p) => p !== oldValue);
                                      if (newValue) updated.push(newValue);
                                      return updated;
                                    });
                                    setFieldValue(`commissionChanges.${index}.productType`, newValue);
                                  }}
                                  errorMessage={touched.commissionChanges?.[index]?.productType
                                    ? errors.commissionChanges?.[index]?.productType
                                    : undefined}
                                  isError={touched.commissionChanges?.[index]?.productType
                                    && !!errors.commissionChanges?.[index]?.productType}
                                />
                              </Grid>

                              <Grid item xs={12} sm={6}>
                                {values.commissionChanges[index].productType === 'bookingFee' ? (
                                  <PBInput
                                    id={`commissionChanges.${index}.newCommissionRate`}
                                    label={`New Booking Fee (${companyInfo.currencySym})`}
                                    type="number"
                                    step="0.1"
                                    variant="outlined"
                                    value={values.commissionChanges[index].newCommissionRate}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled={!values.commissionChanges[index].productType}
                                    errorMessage={
                                    touched.commissionChanges?.[index]?.newCommissionRate
                                      ? errors.commissionChanges?.[index]?.newCommissionRate
                                      : undefined
                                  }
                                    isError={touched.commissionChanges?.[index]?.newCommissionRate
                                    && !!errors.commissionChanges?.[index]?.newCommissionRate}
                                  />
                                ) : (
                                  <PBInput
                                    id={`commissionChanges.${index}.newCommissionRate`}
                                    label="New Commission Rate"
                                    type="percentage"
                                    value={values.commissionChanges[index].newCommissionRate}
                                    onChange={handleChange}
                                    disabled={!values.commissionChanges[index].productType}
                                    errorMessage={
                                    touched.commissionChanges?.[index]?.newCommissionRate
                                      ? errors.commissionChanges?.[index]?.newCommissionRate
                                      : undefined
                                  }
                                    isError={touched.commissionChanges?.[index]?.newCommissionRate
                                    && !!errors.commissionChanges?.[index]?.newCommissionRate}
                                  />
                                )}
                              </Grid>

                              {values.commissionChanges.length > 1 && (
                                <Grid item>
                                  <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={() => {
                                      setSelectedProducts((prev) => prev.filter(
                                        (p) => p !== values.commissionChanges[index].productType,
                                      ));
                                      remove(index);
                                    }}
                                    variant="contained"
                                    size="small"
                                  >
                                    Remove Commission Change
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          );
                        })}

                        <Divider style={{ margin: '1rem 0' }} />

                        <Button
                          type="button"
                          variant="outlined"
                          color="secondary"
                          startIcon={<AddIcon />}
                          onClick={() => push({
                            productType: '',
                            newCommissionRate: '',
                          })}
                          disabled={selectedProducts.length === PRODUCT_TYPES.length}
                          style={{ marginTop: '1rem' }}
                        >
                          Add Another Commission Change
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <PBInput
                    id="fromDate"
                    label="Effective From"
                    type="date"
                    fullWidth
                    value={values.fromDate}
                    onChange={handleChange}
                    errorMessage={touched.fromDate ? errors.fromDate : undefined}
                    isError={touched.fromDate && !!errors.fromDate}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={values.alreadyNotified}
                        onChange={(e) => {
                          setFieldValue('alreadyNotified', e.target.checked);
                          if (!e.target.checked) {
                            setFieldValue('notificationDate', null);
                          }
                        }}
                        color="primary"
                      />
                    )}
                    label="Already notified customer"
                  />
                </Grid>

                {values.alreadyNotified && (
                  <Grid item xs={12} sm={6}>
                    <PBInput
                      id="notificationDate"
                      label="Notification Date"
                      type="date"
                      fullWidth
                      value={values.notificationDate || ''}
                      onChange={handleChange}
                      errorMessage={touched.notificationDate ? errors.notificationDate : undefined}
                      isError={touched.notificationDate && !!errors.notificationDate}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={commissionMutation.isPending}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={commissionMutation.isPending}
              >
                {commissionMutation.isPending ? 'Scheduling...' : 'Schedule Changes'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CommissionRateDialog;
