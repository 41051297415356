import React from 'react';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import ToggleButtons from '../../../../components/ToggleButtons';
import * as accessRestrictionsActions from '../../../../reducers/accessRestrictionsReducer';
import MenuHeading from '../../../../components/MenuHeading';

const startDateButtonsData = [{
  buttonTitle: 'Yes',
  buttonValue: 'YES',
},
{
  buttonTitle: 'No',
  buttonValue: 'NO',
}];

const AccessRestrictionsDialogRecurringValidityPicker = (
  { newAccessRestriction, updateAccessRestrictionCreation, arTypeTitle },
) => {
  const {
    validFrom,
    validTo,
    startDateOption,
    endDateOption,
    focused,
    onFocusChange,
  } = newAccessRestriction;
  return (
    <>
      <MenuHeading title={`Do you want to specify a start date for this ${arTypeTitle}?`} />
      <ToggleButtons
        buttonsData={startDateButtonsData}
        changeOption={(option) => updateAccessRestrictionCreation(
          { startDateOption: option, validFrom: null },
        )}
        value={newAccessRestriction.startDateOption}
      />
      <ConditionallyVisible condition={startDateOption === 'YES'}>
        <DayPickerSingleDateController
          onDateChange={(date) => updateAccessRestrictionCreation({ validFrom: date.startOf('day').format() })}
          onFocusChange={onFocusChange}
          focused={focused}
          date={validFrom !== null ? moment(validFrom) : validFrom}
          noBorder
        />
      </ConditionallyVisible>
      <MenuHeading title={`Do you want to specify an end date for this ${arTypeTitle}?`} />
      <ToggleButtons
        buttonsData={startDateButtonsData}
        changeOption={(option) => updateAccessRestrictionCreation(
          { endDateOption: option, validTo: null },
        )}
        value={newAccessRestriction.endDateOption}
      />
      <ConditionallyVisible condition={endDateOption === 'YES'}>
        <DayPickerSingleDateController
          onDateChange={(date) => updateAccessRestrictionCreation({ validTo: date.endOf('day').format() })}
          onFocusChange={onFocusChange}
          focused={focused}
          date={validTo !== null ? moment(validTo) : validTo}
          noBorder
        />
      </ConditionallyVisible>
    </>
  );
};

AccessRestrictionsDialogRecurringValidityPicker.propTypes = {
  newAccessRestriction: PropTypes.shape().isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
  arTypeTitle: PropTypes.string,
};

AccessRestrictionsDialogRecurringValidityPicker.defaultProps = {
  arTypeTitle: 'access restriction',
};

const mapStateToProps = (state) => ({
  newAccessRestriction: state.accessRestrictions.newAccessRestriction,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccessRestrictionCreation: (keyWithValue) => dispatch(
    accessRestrictionsActions.updateAccessRestrictionCreation(keyWithValue),
  ),
});

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AccessRestrictionsDialogRecurringValidityPicker);
