/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Switch,
} from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import { HighlightOffTwoTone } from '@material-ui/icons';
import { createLibraryNote, updateLibraryNotes } from '../../../services/notesServices';
import { useCompany, useToast, usePitchbookingUser } from '../../../hooks';
import { OverlayLoader } from '../../../components/OverlayLoader';
import MediaConfiguration from '../../../components/MediaConfiguration';

export const LibraryItemDialog = ({
  isEditing, onSuccessCallback, editData,
}) => {
  const company = useCompany();
  const { id: companyId } = company;
  const toast = useToast();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);
  const { id: userId } = usePitchbookingUser();
  const noteId = editData?.id || null;

  const mutation = useMutation({
    mutationFn: async (values) => {
      const data = {
        ...values,
        createdBy: userId,
        noteId,
      };

      let res;
      if (!isEditing) {
        res = await createLibraryNote(companyId, data);
      } else {
        res = await updateLibraryNotes(companyId, data);
      }

      if (res.status !== 200) {
        throw new Error('Error creating exclusion');
      }

      return res.data;
    },
    onSuccess: () => {
      toast.trigger({
        type: 'success',
        message: !isEditing
          ? 'Library item added successfully'
          : 'Library item updated successfully',
      });
      setIsDialogOpen(false);

      if (onSuccessCallback) {
        onSuccessCallback();
      }
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: !isEditing
          ? 'Error adding library item'
          : 'Error updating library item',
      });
    },
  });

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
    url: Yup.string().url('Enter a valid URL').required('URL is required'),
    imageUrl: Yup.string().url('Enter a valid URL'),
  });

  const handleImageUpload = () => {
    setShowImageUpload(false);
    setIsDialogOpen(false);
    onSuccessCallback();
  };

  return (
    <>
      <Button
        variant="contained"
        color={isEditing ? 'secondary' : 'primary'}
        startIcon={isEditing
          ? <EditTwoToneIcon />
          : <AddCircleTwoToneIcon />}
        onClick={handleOpen}
      >
        {isEditing ? 'Edit' : 'Add new library item'}
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        {mutation.isLoading && <OverlayLoader />}

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {isEditing ? 'Edit library item' : 'Add a new library item'}
            </Typography>
            <HighlightOffTwoTone onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              name: editData?.name || '',
              description: editData?.value || '',
              url: editData?.url || '',
              imageUrl: editData?.imageUrl || '',
            }}
            validationSchema={validationSchema}
            onSubmit={(values) => mutation.mutate(values)}
          >
            {({
              errors,
              handleChange,
              touched,
              values,
            }) => (
              <Form style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
                <PBInput
                  type="text"
                  id="name"
                  label="Name"
                  value={values.name}
                  onChange={handleChange}
                  isError={touched.name && Boolean(errors.name)}
                  errorMessage={touched.name && errors.name}
                  required
                />
                <PBInput
                  type="text"
                  id="description"
                  label="Description"
                  value={values.description}
                  onChange={handleChange}
                  isError={touched.description && Boolean(errors.description)}
                  errorMessage={touched.description && errors.description}
                />
                <PBInput
                  type="text"
                  id="url"
                  label="URL"
                  value={values.url}
                  onChange={handleChange}
                  isError={touched.url && Boolean(errors.url)}
                  errorMessage={touched.url && errors.url}
                  required
                />
                <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <div style={{ width: '100%' }}>
                    <PBInput
                      type="text"
                      id="imageUrl"
                      label="Image URL"
                      value={values.imageUrl}
                      onChange={handleChange}
                      isError={touched.imageUrl && Boolean(errors.imageUrl)}
                      errorMessage={touched.imageUrl && errors.imageUrl}
                      disabled={showImageUpload}
                    />
                    {!isEditing && (
                      <Typography variant="caption" style={{ color: 'gray', fontWeight: 600, padding: '0rem 0.2rem' }}>
                        Note: You can upload an image by editing the library item after creation
                      </Typography>
                    )}
                  </div>

                  {isEditing && (
                    <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
                      Upload image

                      <Switch
                        checked={showImageUpload}
                        onChange={() => setShowImageUpload(!showImageUpload)}
                        color="primary"
                      />
                    </Typography>
                  )}
                </div>

                {showImageUpload && (
                  <MediaConfiguration
                    type="LIBRARY"
                    resourceId={noteId}
                    initialImage={values.imageUrl}
                    reloadResource={() => handleImageUpload()}
                  />
                )}

                <DialogActions>
                  <Button variant="contained" onClick={handleClose} disabled={mutation.isLoading}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" type="submit" disabled={mutation.isLoading}>
                    Confirm
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};
