/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { WhatsApp } from '@material-ui/icons';

export const ShareToWhatsApp = ({ whatsAppUrl }) => {
  const [hoverColor, setHoverColor] = useState(null);

  const whatsAppBaseUrl = 'https://api.whatsapp.com/send?phone=whatsappphonenumber';
  const shareUrl = new URL(whatsAppBaseUrl);

  const handleShare = () => {
    shareUrl.searchParams.append('text', whatsAppUrl); // URL to share

    window.open(
      shareUrl.toString(),
      '_blank',
      'noopener,noreferrer,width=600,height=400',
    );
  };

  return (
    <WhatsApp
      style={{
        cursor: 'pointer',
        color: hoverColor !== null ? hoverColor : null,
        fontSize: '3rem',
      }}
      onMouseEnter={() => setHoverColor('#25D366')}
      onMouseLeave={() => setHoverColor(null)}
      onClick={handleShare}
    />
  );
};
