import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import UserSelectionInfoAlert from '../../../components/UserSelectionInfoAlert';
import { useCompany } from '../../../hooks';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  teamName: Yup.string(),
  phone: Yup.string().matches(/^[0-9]+$/, 'Phone must be numeric'),
});

const NewBookerUserCreation = ({
  user,
  subscriptionUser,
  updateUserDetails,
  subscription,
  league,
  leagueUser,
  associate,
  associateUser,
}) => {
  const company = useCompany();

  useEffect(() => {
    if (company?.dialcode && company.dialcode !== user?.dialCode) {
      updateUserDetails({ dialCode: company.dialcode });
    }
  }, [company, updateUserDetails, user?.dialCode]);

  const {
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    teamName = '',
    dialCode = '',
  } = useMemo(() => {
    if (subscription) return subscriptionUser;
    // if (league) return leagueUser; // TODO: this is causing a white screen on live
    if (associate) return associateUser;
    return user;
  }, [user, subscription, league, associate, subscriptionUser, leagueUser, associateUser]);

  const handleUpdateDetails = (key, value) => {
    if (user[key] !== value) {
      updateUserDetails({ [key]: value });
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          teamName,
          phone,
          dialCode,
        }}
        validationSchema={validationSchema}
      >
        {({
          errors, touched, handleBlur, setFieldValue,
        }) => (
          <Form>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {/* FirstName / LastName */}
              <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
                <PBInput
                  id="firstName"
                  type="text"
                  label="First Name"
                  value={firstName}
                  onChange={(event) => {
                    handleUpdateDetails('firstName', event.target.value);
                    setFieldValue('firstName', event.target.value);
                  }}
                  required
                  onBlur={handleBlur}
                  touched={touched.firstName}
                  errorMessage={touched.firstName && errors.firstName}
                  isError={touched.firstName && Boolean(errors.firstName)}
                />
                <PBInput
                  id="lastName"
                  type="text"
                  label="Last Name"
                  value={lastName}
                  onChange={(event) => {
                    handleUpdateDetails('lastName', event.target.value);
                    setFieldValue('lastName', event.target.value);
                  }}
                  required
                  onBlur={handleBlur}
                  touched={touched.lastName}
                  errorMessage={touched.lastName && errors.lastName}
                  isError={touched.lastName && Boolean(errors.lastName)}
                />
              </div>

              {/* Email / TeamName */}
              <div style={{ display: 'flex', width: '100%', gap: '1rem' }}>
                <PBInput
                  id="email"
                  type="email"
                  label="Email Address"
                  value={email}
                  onChange={(event) => {
                    handleUpdateDetails('email', event.target.value.trim());
                    setFieldValue('email', event.target.value.trim());
                  }}
                  required
                  onBlur={handleBlur}
                  touched={touched.email}
                  errorMessage={touched.email && errors.email}
                  isError={touched.email && Boolean(errors.email)}
                />
                <PBInput
                  id="teamName"
                  type="text"
                  label="Team name"
                  value={teamName}
                  onChange={(event) => {
                    handleUpdateDetails('teamName', event.target.value);
                    setFieldValue('teamName', event.target.value);
                  }}
                  onBlur={handleBlur}
                  touched={touched.teamName}
                  errorMessage={touched.teamName && errors.teamName}
                  isError={touched.teamName && Boolean(errors.teamName)}
                />
              </div>

              {/* Phone */}
              <PBInput
                id="phone"
                type="phone"
                value={{ phone, dialCode: dialCode || '44' }}
                onChange={(event) => {
                  handleUpdateDetails('phone', event.target.value);
                  setFieldValue('phone', event.target.value);
                }}
                onChangeDialcode={(event) => handleUpdateDetails('dialCode', event.target.value)}
                onBlur={handleBlur}
                touched={touched.phone}
                errorMessage={touched.phone && errors.phone}
                isError={touched.phone && Boolean(errors.phone)}
              />
            </div>
          </Form>
        )}
      </Formik>

      {/* Info message */}
      <UserSelectionInfoAlert newUser booking={false} />
    </>
  );
};

NewBookerUserCreation.propTypes = {
  subscriptionUser: PropTypes.shape().isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    teamName: PropTypes.string,
    phone: PropTypes.string,
    dialCode: PropTypes.string,
  }).isRequired,
  updateUserDetails: PropTypes.func.isRequired,
  subscription: PropTypes.bool.isRequired,
  league: PropTypes.bool.isRequired,
  leagueUser: PropTypes.shape().isRequired,
  associate: PropTypes.bool.isRequired,
  associateUser: PropTypes.shape().isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  user: ownProps.user,
  subscriptionUser: state.subscriptions.userDetailsForSubscription,
  leagueUser: state.leagues.invitedUser,
  associateUser: state.users.associate,
});

export default connect(mapStateToProps, null)(NewBookerUserCreation);
