/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useMutation } from '@tanstack/react-query';
import { updateCompanyDetailsService } from '../../../services/companiesServices';

const formatCommissionRate = (rate, type) => {
  if (type === 'bookingFee') {
    return `£${parseFloat(rate).toFixed(2)}`;
  }
  return `${(rate * 100).toFixed(1)}%`;
};

const getProductLabel = (type) => {
  const labels = {
    facility: 'Facilities',
    event: 'Events',
    league: 'Leagues',
    membership: 'Memberships',
    terminal: 'Terminal',
    bookingFee: 'Booking Fee',
  };
  return labels[type] || type;
};

const UpcomingCommissionRates = ({ companyId, accounting, onDelete }) => {
  const futureChanges = useMemo(() => {
    const upcomingChanges = accounting.commissionChanges || [];
    const now = moment();

    return upcomingChanges
      .filter((change) => moment(change.fromDate).isAfter(now))
      .sort((a, b) => moment(a.fromDate).diff(moment(b.fromDate)));
  }, [accounting.commissionChanges]);

  const historicalChanges = useMemo(() => accounting.commissionChanges.filter(
    (change) => moment(change.fromDate).isBefore(moment()),
  ), [accounting.commissionChanges]);

  const commissionMutation = useMutation({
    mutationFn: async (indexToDelete) => {
      const changes = futureChanges.filter((x, index) => index !== indexToDelete);

      const { data } = await updateCompanyDetailsService(
        companyId,
        {
          accounting: {
            ...accounting,
            commissionChanges: changes,
          },
        },
      );
      return data;
    },
    onSuccess: (data) => {
      onDelete(data);
    },
    onError: (error) => {
      console.error(error.message || 'Failed to delete commission change');
    },
  });

  if (futureChanges.length === 0 && historicalChanges.length === 0) {
    return null;
  }

  const handleDelete = (change) => {
    if (window.confirm('Are you sure you want to delete this commission change?')) {
      commissionMutation.mutate(change);
    }
  };

  return (
    <>
      {futureChanges.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Upcoming Commission Changes
          </Typography>
          <Grid container spacing={2}>
            {futureChanges.map((change, index) => (
              <Grid item xs={12} key={index}>
                <Card>
                  <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div>
                      <Typography variant="subtitle1" color="textSecondary">
                        Effective from:
                        {' '}
                        {moment(change.fromDate).format('DD MMM YYYY')}
                      </Typography>
                      <Typography variant="body1">
                        {getProductLabel(change.productType)}
                        :
                        {' '}
                        {formatCommissionRate(change.oldCommissionRate, change.productType)}
                        {' '}
                        →
                        {' '}
                        {formatCommissionRate(change.newCommissionRate, change.productType)}
                      </Typography>
                      {change.notifiedOn && (
                      <Typography variant="body2" color="textSecondary">
                        Customer notified on:
                        {' '}
                        {moment(change.notifiedOn).format('DD MMM YYYY')}
                      </Typography>
                      )}
                    </div>
                    <Tooltip title="Delete commission change">
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(index)}
                        disabled={commissionMutation.isPending}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {historicalChanges.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom>
            Historical Commission Changes
          </Typography>
          <Grid container spacing={2}>
            {historicalChanges.map((change, index) => (
              <Grid item xs={12} key={index}>
                <Card>
                  <CardContent style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <div>
                      <Typography variant="body1">
                        {`${getProductLabel(change.productType)}: ${formatCommissionRate(change.oldCommissionRate, change.productType)} → ${formatCommissionRate(change.newCommissionRate, change.productType)}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {`Effective from: ${moment(change.fromDate).format('DD MMM YYYY')}`}
                      </Typography>
                      {change.appliedOn && (
                        <Typography variant="body2" color="textSecondary">
                          {`Applied on: ${moment(change.appliedOn).format('DD MMM YYYY')}`}
                        </Typography>
                      )}
                      {change.notifiedOn && (
                        <Typography variant="body2" color="textSecondary">
                          {`Customer notified on: ${moment(change.notifiedOn).format('DD MMM YYYY')}`}
                        </Typography>
                      )}
                      {change.appliedOn && (
                      <Typography variant="body2" color="textSecondary">
                        {`Applied on: ${moment(change.appliedOn).format('DD MMM YYYY')}`}
                      </Typography>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default UpcomingCommissionRates;
