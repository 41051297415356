import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography, Button } from '@material-ui/core';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import AttachMoneyTwoToneIcon from '@material-ui/icons/AttachMoneyTwoTone';
import MoneyOffTwoToneIcon from '@material-ui/icons/MoneyOffTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import EditTwoToneIcon from '@material-ui/icons/EditTwoTone';
import BackspaceTwoToneIcon from '@material-ui/icons/BackspaceTwoTone';
import LocalOfferTwoToneIcon from '@material-ui/icons/LocalOfferTwoTone';
import InsertDriveFileTwoToneIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { invoiceNumberFormatted } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';
import { getInvoiceInformationService } from '../../services/companiesServices';
import { useCompany, useToast, useOrigin } from '../../hooks';
import { StatusTag, invoiceStatusColors } from '../../components/StatusTag';
import { getInvoiceLabelStatus, handleDownload } from './helperFunctions';
import { updateInvoice } from '../../reducers/invoicesReducer';
import InvoiceNoteSection from './InvoiceNoteSection';
import ResendInvoice from './ResendInvoice';
import OrderTable from '../payments/OrderTable';
import InvoiceRecordPaymentDialog from './InvoiceRecordPaymentDialog';
import VoidInvoiceDialog from './VoidInvoiceDialog';
import PreviewInvoiceDialog from './PreviewInvoiceDialog';

export const InvoiceView = ({ match: { params: { invoiceId } } }) => {
  const { id: companyId, timezone, currencySym } = useCompany();
  const dispatch = useDispatch();
  const { managerUrl } = useOrigin();

  const toast = useToast();
  const { invoices, invoice: invoiceNote } = useSelector((state) => state.invoices);
  const [invoice, setInvoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const statusColor = useMemo(() => (
    invoice?.status ? invoiceStatusColors[invoice?.status]?.colorScheme.borderColor : 'var(--accent)'
  ), [invoice?.status]);

  const fetchInvoice = async () => {
    try {
      const res = await getInvoiceInformationService(companyId, invoiceId);
      if (res.status === 200) {
        setInvoice(res.data[0]);
        setIsEditingNotes(false);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (!companyId || !invoiceId) return;
    fetchInvoice();
  }, [companyId, invoices, invoiceNote]);

  const renderListItem = (icon, label) => (
    <Typography
      variant="body1"
      style={{
        display: 'flex', gap: '1rem', alignItems: 'center', fontWeight: 600,
      }}
    >
      <span style={{ color: statusColor }}>
        {icon}
      </span>
      {label}
    </Typography>
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '1rem 2rem',
          borderRadius: '1rem',
          border: '1px solid #e0e0e0',
          margin: '1.25rem',
          background: '#fff',
          gap: '1rem',
        }}
      >
        {/* Title && Back */}
        <div style={{
          display: 'flex', width: '100%', gap: '1rem', alignItems: 'center', marginBottom: '1rem',
        }}
        >
          <BackspaceTwoToneIcon style={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={() => window.history.back()} />
          <Typography variant="h5" style={{ fontWeight: 600 }}>Invoice Details</Typography>
        </div>

        {isLoading
          ? (
            <div style={{
              display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: '1rem',
            }}
            >
              <CircularProgress size={40} />
              <Typography variant="h6" color="primary" style={{ fontWeight: 600 }}>Loading invoice...</Typography>
            </div>
          )
          : invoice && (
          <>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: '0.5rem', width: 'fit-content', whiteSpace: 'nowrap', padding: '0rem 1rem',
            }}
            >
              {renderListItem(<LocalOfferTwoToneIcon style={{ fontSize: '2rem' }} />, `Invoice Number: ${invoiceNumberFormatted(invoice?.invoiceNumber)}`)}

              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {renderListItem(<AccountBalanceTwoToneIcon style={{ fontSize: '2rem' }} />, 'Payment status:')}
                <StatusTag
                  label={getInvoiceLabelStatus(invoice?.status, invoice?.dueDate, timezone)}
                  colorScheme={invoiceStatusColors[invoice?.status]?.colorScheme}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {renderListItem(<PersonOutlineTwoToneIcon style={{ fontSize: '2rem' }} />, `User: ${invoice?.user?.firstName} ${invoice?.user?.lastName}`)}
                <a href={`/users/${invoice?.user?.id}`}>
                  <Typography variant="caption" style={{ color: 'var(--accent)' }}>View profile</Typography>
                </a>
              </div>

              {renderListItem(<EmailTwoToneIcon style={{ fontSize: '2rem' }} />, `Email: ${invoice?.user?.email}`)}
              {renderListItem(<AttachMoneyTwoToneIcon style={{ fontSize: '2rem' }} />, `Total: ${currencySym}${(invoice?.total?.toFixed(2))}`)}
              {renderListItem(<AccountBalanceTwoToneIcon style={{ fontSize: '2rem' }} />, `Tax: ${currencySym}${(invoice?.tax?.toFixed(2))}`)}
              {renderListItem(<MoneyOffTwoToneIcon style={{ fontSize: '2rem' }} />, `Total excluding tax: ${currencySym}${(invoice?.totalExTax?.toFixed(2))}`)}

              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {renderListItem(<InsertDriveFileTwoToneIcon style={{ fontSize: '2rem' }} />, 'Order')}
                <a href={`${managerUrl}/payments/${invoice.orderId}`}>
                  <Typography variant="caption" style={{ color: 'var(--accent)' }}>View Order</Typography>
                </a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {renderListItem(<InsertDriveFileTwoToneIcon style={{ fontSize: '2rem' }} />, `Notes: ${(invoice?.notes || '')}`)}
                {invoice?.notes && !isEditingNotes ? (
                  <Typography
                    variant="caption"
                    style={{
                      display: 'flex', alignItems: 'center', color: 'var(--accent)', cursor: 'pointer',
                    }}
                    onClick={() => setIsEditingNotes(true)}
                  >
                    <EditTwoToneIcon style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
                    Edit Notes
                  </Typography>
                ) : (
                  <>
                    <InvoiceNoteSection
                      updateInvoice={() => dispatch(updateInvoice(invoiceId))}
                      invoiceId={invoice.id}
                      existingNote={invoice.notes}
                    />
                    {isEditingNotes && (
                    <Button variant="contained" onClick={() => setIsEditingNotes(false)}>Cancel</Button>
                    )}
                  </>
                )}
              </div>

              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                {renderListItem(<ReceiptTwoToneIcon style={{ fontSize: '2rem' }} />, 'Preview Invoice:')}
                <PreviewInvoiceDialog
                  invoice={invoice}
                  invoiceId={invoice?.id}
                  isInvoicePage
                />
              </div>
            </div>

            {/* Actions */}
            <div style={{
              display: 'flex', gap: '1rem', marginTop: '1rem', alignItems: 'center',
            }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleDownload(invoice, (message) => (message
                  ? toast.trigger({ type: 'success', message: 'Invoice downloaded successfully!' })
                  : toast.trigger({ type: 'error', message: 'Failed to download invoice!' })
                ))}
                style={{ height: 'fit-content' }}
              >
                Export PDF
              </Button>

              {invoice?.status === 'UNPAID' && (
              <>
                <ResendInvoice invoiceId={invoice.id} />
                <ResendInvoice invoiceId={invoice.id} toAdditionalUser />
                <VoidInvoiceDialog invoice={invoice} />
                <InvoiceRecordPaymentDialog invoice={invoice} />
              </>
              )}
            </div>
          </>
          )}
      </div>

      {!isLoading && invoice?.order && (
      <OrderTable
        orders={[invoice.order]}
        currencySym={currencySym}
        showUser={false}
      />
      )}
    </>
  );
};

InvoiceView.propTypes = {
  match: PropTypes.shape().isRequired,
};
