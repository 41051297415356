/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { CreditCard, ArrowBackIosRounded } from '@material-ui/icons';
import StripeCheckout from '@pitchbooking-dev/pb-shared/lib/components/Stripe/StripeCheckout';
import { useDispatch } from 'react-redux';
import { useReward } from 'react-rewards';
import { usePitchbookingUser } from '../../../../hooks';
import AddTeamMemberDialog from '../../../teamMembers/components/AddTeamMemberDialog';
import { setStripeBilling } from '../../../../reducers/companiesReducer';

export const PaymentBar = ({
  billing, billingCreationStatus, isMobile,
}) => {
  const dispatch = useDispatch();
  const { reward: confettiReward, isAnimating } = useReward('confettiReward', 'confetti');
  const currentUser = usePitchbookingUser();
  const { id: userId } = currentUser;
  const { status: billStatus, amount: billAmount, company } = billing;
  const billId = billing.id
  || company?.billings?.filter((billing) => (billing.type === 'STRIPE' || billing.gameOn) && billing.status === 'PENDING')[0].id;

  const [isPaymentBarExpanded, setIsPaymentBarExpanded] = useState(false);
  const [agreementCompleted, setAgreementCompleted] = useState(false);
  const [agreementStatusText, setAgreementStatusText] = useState('');

  const hanleCompleteAgreement = () => {
    confettiReward();

    const stripeBillingData = {
      setupIntent: {
        metadata: {
          transactionId: billId,
          userId,
        },
      },
    };

    dispatch(setStripeBilling(stripeBillingData));
    setAgreementCompleted(true);
  };

  useEffect(() => {
    if (billStatus === 'CONFIRMED' || billStatus === 'PROCESSING') {
      setAgreementCompleted(true);
    }

    if (agreementCompleted && isPaymentBarExpanded) {
      confettiReward();
    }

    if (billStatus === 'PROCESSING') {
      setAgreementStatusText('Welcome to Pitchbooking! We\'re processing your agreement.');
    } else if (billStatus === 'CONFIRMED') {
      setAgreementStatusText('Welcome to Pitchbooking! Your agreement has been confirmed!');
    }
  }, [agreementCompleted, isPaymentBarExpanded, billStatus]);

  return (
    <div
      style={{
        position: 'fixed',
        bottom: 0,
        backgroundColor: isPaymentBarExpanded ? '#fff' : null,
        boxShadow: isPaymentBarExpanded ? '0 -2px 10px rgba(0,0,0,0.3)' : null,
        zIndex: 1000,
        display: 'flex',
        width: '-webkit-fill-available',
        margin: !isMobile ? '0 5rem' : '0',
        alignItems: 'center',
        justifyContent: 'center',
        borderTopLeftRadius: '0.5rem',
        borderTopRightRadius: '0.5rem',
        overflow: 'hidden',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Button
          onClick={() => setIsPaymentBarExpanded(!isPaymentBarExpanded)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: 'rgb(var(--primary))',
            color: '#000',
            padding: '1rem',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderRadius: 0,
            cursor: 'pointer',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <CreditCard style={{ marginRight: '0.5rem', fontSize: '1.75rem' }} />
            {billing.amount > 0 ? 'Sign Agreement' : 'Complete Agreement'}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              transition: 'transform 0.3s ease',
              transform: isPaymentBarExpanded
                ? 'rotate(180deg)'
                : 'rotate(0deg)',
            }}
          >
            <ArrowBackIosRounded
              style={{ fontSize: '1.5rem', transform: 'rotate(90deg)' }}
            />
          </div>
        </Button>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span id="confettiReward" />
        </div>

        {/* Payment menu content */}
        {billAmount > 0 ? (
          isPaymentBarExpanded && (
          <div
            style={{
              padding: '2rem',
              borderTop: '1px solid #ddd',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
              maxHeight: 'calc(100vh - 8rem)',
              overflowY: 'auto',
            }}
          >
            {!agreementCompleted && (
              <Typography style={{ fontSize: '1.25rem', fontWeight: 700 }}>
                Payment Details
              </Typography>
            )}

            {billStatus === 'PENDING' && (
              <div style={{
                display: 'flex', flexDirection: 'column', gap: '1rem',
              }}
              >
                <Typography style={{ fontSize: '0.9rem', fontWeight: 500, color: 'rgb(75, 85, 99)' }}>
                  Please Note:
                  <span style={{ fontStyle: 'italic' }}>
                    {` If you are not the correct person to complete this agreement,
                    please add the correct person as a Finance Officer below.`}
                  </span>
                </Typography>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <AddTeamMemberDialog title="Add Finance Officer" />
                </div>
              </div>
            )}

            { agreementCompleted || billingCreationStatus.processing ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography style={{
                  fontSize: '1.5rem',
                  fontWeight: 500,
                  color: 'rgb(75, 85, 99)',
                  padding: '2rem 0',
                  cursor: 'default',
                }}
                >
                  {`🎉 ${agreementStatusText} 🎉`}
                </Typography>
              </div>
            ) : (
              <StripeCheckout
                companyStripeId="acct_1CS6MjD87o6fmXTg"
                mode="SETUP_INTENT"
                transactionType="BILLING"
                transactionId={billing.id}
                redirectUrl={`${window.location.origin}/agreement/${billing.id}/processing`}
                buttonText="Confirm Details"
              />
            )}
          </div>
          )
        ) : (
          isPaymentBarExpanded && (
          <div
            style={{
              padding: '2rem',
              borderTop: '1px solid #ddd',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.5rem',
            }}
          >
            {!agreementCompleted && (
              <Typography style={{ fontSize: '0.9rem', fontWeight: 500, color: 'rgb(75, 85, 99)' }}>
                Click the button below to complete the agreement now and start using Pitchbooking.
              </Typography>
            )}

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {agreementCompleted ? (
                <Typography style={{
                  fontSize: '1.5rem',
                  fontWeight: 500,
                  color: 'rgb(75, 85, 99)',
                  padding: '2rem 0',
                  cursor: 'default',
                }}
                >
                  {`🎉 ${agreementStatusText} 🎉`}
                </Typography>
              ) : (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isAnimating}
                    onClick={hanleCompleteAgreement}
                  >
                    Complete Agreement
                  </Button>
                </>
              )}
            </div>
          </div>
          )
        )}
      </div>
    </div>
  );
};
