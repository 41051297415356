/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import AddCircleTwoToneIcon from '@material-ui/icons/AddCircleTwoTone';
import { HighlightOffTwoTone } from '@material-ui/icons';
import { CardNotPresent } from '../bookings/components/CardNotPresent';
import { useCompany } from '../../hooks';

import PaymentStatusRadio from '../../components/PaymentStatusRadio';
import Terminal from '../../components/Stripe/Terminal';
import { updateAddonsReservation } from '../../reducers/addonsReducer';

export const PosPaymentDialog = ({
  selectedUser, createReservations, disabled,
}) => {
  const company = useCompany();
  const { id: companyId, stripeId } = company;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const reservation = useSelector((state) => state.addons.reservation);
  const [disableChangePaymentStatus, setDisableChangePaymentStatus] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setDisableChangePaymentStatus(false);
    setPaymentStatus(null);
    setIsDialogOpen(false);
    // clear reservation from state addons
    dispatch(updateAddonsReservation(null));
  };

  useEffect(() => {
    // create allocations for stripe payments
    if ((paymentStatus === 'CARD_NOT_PRESENT' && selectedUser) || paymentStatus === 'TERMINAL') {
      createReservations(null, true);
      setDisableChangePaymentStatus(true);
    }
  }, [paymentStatus]);

  const handlePayment = () => {
    createReservations(paymentStatus);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        color="primary"
        startIcon={<AddCircleTwoToneIcon />}
        onClick={handleOpen}
        style={{ width: 180 }}
      >
        Pay
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              Select Payment Method
            </Typography>
            <HighlightOffTwoTone onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>
        <DialogContent>

          <PaymentStatusRadio
            disabled={disableChangePaymentStatus}
            setPaymentStatus={
              (selectedPaymentStatus) => setPaymentStatus(selectedPaymentStatus)
            }
          />

          {paymentStatus === 'CARD_NOT_PRESENT' && selectedUser && reservation && (
            <CardNotPresent
              paymentMethod={paymentStatus}
              booking={reservation}
              metadata={
                {
                  ...(selectedUser ? {
                    userId: selectedUser.id,
                  } : {}),
                }
              }
              onSuccess={() => {
                dispatch(push('/point-of-sale/'));
                window.location.reload();
              }}
            />
          )}
          {paymentStatus === 'CARD_NOT_PRESENT' && !selectedUser && (
            <Typography color="error">
              Please select a customer to record a payment with Card Not Present.
            </Typography>
          )}
          {paymentStatus === 'TERMINAL' && reservation && (
            <Terminal
              transactionType="RESERVATION_PAYMENT"
              companyStripeId={stripeId}
              userId={selectedUser?.id ?? null}
              companyId={companyId}
              transactionId={reservation?.id}
              onSuccess={() => {
                dispatch(push('/point-of-sale'));
                window.location.reload();
              }}
              metadata={{
                ...(selectedUser && {
                  ...(selectedUser.id ? { userId: selectedUser.id } : {
                    user: JSON.stringify({
                      id: selectedUser.id,
                      firstName: selectedUser.firstName,
                      lastName: selectedUser.lastName,
                      teamName: selectedUser.teamName,
                      phoneNumber: selectedUser.phone,
                      email: selectedUser.email,
                    }),
                  }),
                }),
              }}
            />
          )}

          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
              disabled={!paymentStatus || paymentStatus === 'CARD_NOT_PRESENT' || paymentStatus === 'TERMINAL'}
            >
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
};
