/* eslint-disable max-len */
/* eslint-disable no-constant-condition */
import {
  takeEvery, call, put, select, all,
} from 'redux-saga/effects';
import Moment from 'moment-timezone';
import * as basketActions from '@pitchbooking-dev/pb-shared/lib/reducers/basketReducer';
import {
  resetTimetableData, updateTimetableSelectedTimeslots, succeedTimetablePriceRetrieval, changeTimetableSelectedDate,
} from '@pitchbooking-dev/pb-shared/lib/actions/timetableActions';
import { getCombinedSlots } from '@pitchbooking-dev/pb-shared/lib/selectors/timetableSelectors';
import * as sessionActions from '@pitchbooking-dev/pb-shared/lib/reducers/sessionReducer';
import { retrieveTimetableAvailabilitySaga } from '@pitchbooking-dev/pb-shared/lib/sagas/timetableSaga';
import { getUserTimeTableslotsService, getTimetableSelectedTimeSlotsPrice } from '@pitchbooking-dev/pb-shared/lib/services/timetableService';
import {
  createManagerReservationService,
  updateReservationServices,
  cancelReservationsServices,
  recordMultiplePaymentService,
} from '../services/reservationsServices';
import {
  createManagerAllocationsService,
  getBookingService,
} from '../services/bookingsServices';
import {
  getSubscriptionService,
  refundSubscriptionServices,
} from '../services/subscriptionsServices';
import * as actions from '../reducers/reservationsReducer';
import * as bookingsActions from '../reducers/bookingsReducer';
import * as calendarActions from '../reducers/calendarReducer';
import * as facilitiesActions from '../reducers/facilitiesReducer';
import * as subscriptionsActions from '../reducers/subscriptionsReducer';
import { handleSagaError } from './helperSaga';
import { combineTimeslots, findNearestDefinedStartTime } from '../utils/timetable.utils';

// CREATE
function* createManagerReservationSaga(companyId) {
  const state = yield select();
  const {
    facilities, reservation, basket, router,
  } = state;
  const { selectedFacility } = facilities;
  const {
    userDetailsForReservation, selectedPaymentStatus, selectedAgeGroup, selectedEvent,
  } = reservation;
  const { confirmedAllocations } = basket;
  const { location } = router;
  const { pathname } = location;
  const combinedNotes = `${userDetailsForReservation.adminNotes}`;

  const reservationData = {
    facilityId: selectedFacility.id,
    facilityName: selectedFacility.name,
    slots: confirmedAllocations,
    userEmailFor: userDetailsForReservation.email,
    ageGroupBookingModifierId: selectedAgeGroup,
    eventBookingModifierId: selectedEvent,
    facilityAccessInstructions: selectedFacility.accessInstructions,
    sport: reservation?.sport ?? selectedFacility.sport[0],
    paymentStatus: selectedPaymentStatus,
    requestToBook: false,
    notes: combinedNotes,
    sendNotesToBooker: userDetailsForReservation.sendNotesToBooker,
  };

  try {
    const response = yield call(
      createManagerReservationService,
      companyId,
      reservationData,
    );

    const getCompanyInfo = (state) => state.companies.companyInfo;
    const companyInfo = yield select(getCompanyInfo);
    const currencySymbol = companyInfo?.currencySym;

    if (!response.error) {
      if (pathname === '/calendar') {
        yield put(calendarActions.getCalendarUsage());
      }
      if (reservationData.paymentStatus === 'EXT_POS') {
        const reservations = response.data;
        const { order } = reservations[0];
        let allocations = reservations.map((reservation) => reservation.allocations).flat();
        allocations = allocations.map((all) => ({
          facility: all.facility,
          eventBookingModifier: all.eventBookingModifier,
          total: all.price,
        }));
        window.create_transaction(allocations, order.id);
      }
      yield put(actions.succeedManagerReservationCreation(response.data));

      let successfulBookingMessage = '';
      const combinedFacilities = new Set();
      let combinedTotal = 0;
      let userFullName = '';

      if (response?.data && response?.data.length > 0) {
        response.data.forEach((reservation) => {
          if (!reservation) return;
          const { allocations, user, total } = reservation;

          if (typeof total === 'number') {
            combinedTotal += total;
          }

          userFullName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();

          if (Array.isArray(allocations)) {
            allocations.forEach((allocation) => {
              const facilityName = allocation?.facility?.name;
              if (facilityName) {
                combinedFacilities.add(facilityName);
              }
            });
          }
        });

        const facilitiesArray = Array.from(combinedFacilities);
        const facilitiesList = facilitiesArray.length > 1
          ? `${facilitiesArray.slice(0, -1).join(', ')} & ${facilitiesArray.slice(-1)}`
          : facilitiesArray.join('');

        successfulBookingMessage = `Booking successful for facilities: ${facilitiesList} for user ${userFullName} at a total of ${currencySymbol || ''}${combinedTotal.toFixed(2)}.`;
      }
      yield put(sessionActions.updateSuccessfulSnackbar(successfulBookingMessage, true));
      // yield put(sessionActions.updateSuccessfulSnackbar('Booking Successful', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'createManagerReservationSaga');
    if (error.data && error.error) {
      yield put(actions.failManagerReservationCreation(
        error.data.error,
        error.data.erroredAllocations,
      ));
      return;
    }
    yield put(sessionActions.updateErrorSnackbar(`Booking Failed : ${error}`, true));
  }
}

// UPDATE
function* updateReservationSaga(companyId, reservationAction) {
  const state = yield select();
  try {
    const response = yield call(
      updateReservationServices,
      companyId,
      reservationAction.reservationId,
      reservationAction.reqBody,
    );
    if (!response.error) {
      let { dates } = state.bookings;
      if (!dates.fromDate) {
        dates = {
          fromDate: Moment().format('YYYY-MM-DD'),
          toDate: Moment().add(7, 'days').format('YYYY-MM-DD'),
        };
      }
      yield put(bookingsActions.getBookings(dates));
      yield put(sessionActions.updateSuccessfulSnackbar('Booking Updated Successfully', true));
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'updateReservationSaga');
    yield put(sessionActions.updateErrorSnackbar('Booking Updated Failed', true));
  }
}

function* recordMulitplePaymentSaga(
  companyId,
  {
    reservations,
    singularBooking,
  },
) {
  const state = yield select();
  const {
    bookings,
    companies,
    addons,
  } = state;
  const { companyInfo } = companies;
  const { posOrder } = addons;
  const { timezone } = companyInfo;
  const { recordPaymentBookings } = state.reservation;
  if (recordPaymentBookings.paidAt === null) {
    recordPaymentBookings.paidAt = Moment().tz(timezone).utc().format();
  }
  const { reservation, selectedRows } = bookings;
  const reqBody = {
    posOrder,
    reservations,
    ...recordPaymentBookings,
  };

  try {
    const response = yield call(
      recordMultiplePaymentService,
      companyId,
      reqBody,
    );

    if (!response.error) {
      // Refetch the reservation
      if (!reservations.some((x) => x.type === 'SUBSCRIPTION') && recordPaymentBookings?.reservationId) {
        const response = yield call(
          getBookingService, companyId, recordPaymentBookings.reservationId,
        );

        if (response.error) {
          throw response.error;
        }

        const data = response.data[0];

        const selectedRowShape = {
          ...data,
          slot: data.startTime,
          id: data.id
            ? data.id
            : data.subscriptionId,
          userId: data.user.id,
          allocations: data.allocations.concat(
            data.amenities,
          ),
        };

        yield put(bookingsActions.updateSelectedRowsPartialPayment(selectedRowShape));
        yield put(sessionActions.updateSuccessfulSnackbar('Payment Recorded Successfully', true));
      }

      // Refetch the subscription
      if (!reservations.some((x) => x.type === 'RESERVATION' && recordPaymentBookings.reservationId)) {
        const response = yield call(
          getSubscriptionService, companyId, recordPaymentBookings.reservationId,
        );

        if (response.error) {
          throw response.error;
        }

        const { slot } = selectedRows[0];
        const { data } = response;
        const slotOrders = data.orders?.filter((x) => x.subscription_order.slot === slot);
        const partialPaymentOrders = data.partialPaymentOrders?.filter(
          (x) => Moment(x.subscription_order.slot).tz(timezone).format('YYYY-MM-DD') === Moment(slot).tz(timezone).format('YYYY-MM-DD'),
        );
        const selectedRowShape = {
          ...data,
          orders: slotOrders,
          partialPaymentOrders,
          paid: slotOrders?.some((x) => !!x.paidAt && x.status === 'CREATED') ?? false,
          slot,
          userId: data.user.id,
          total: data.orders[0].total / 100,
          totalExTax: data.orders[0].totalExTax,
          tax: data.orders[0].tax,
          subscriptionId: data.id,
          type: 'SUBSCRIPTION',
        };

        yield put(bookingsActions.updateSelectedRowsPartialPayment(selectedRowShape));
        yield put(sessionActions.updateSuccessfulSnackbar('Payment Recorded Successfully', true));
      }

      if (response.data.status === 'PENDING' && response.data.EXTProductId) {
        const { id } = response.data;
        window.create_transaction(reservations, id);
      }

      if (singularBooking) {
        yield put(bookingsActions.getBooking(reservation.id));
      }

      yield put(actions.recordMultipleReservationPaymentSuccess());
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'recordMulitplePaymentSaga');
    yield put(actions.recordMultipleReservationPaymentFailure(error.error));
    yield put(sessionActions.updateErrorSnackbar('Payment not recorded', true));
  } finally {
    yield put(calendarActions.getCalendarUsage());
    yield put(
      actions.updateRecordPaymentStore({
        isPaymentProcessing: false,
      }),
    );
  }
}

function* cancelReservationsSaga(companyId, reservationAction) {
  const {
    reservations, cancelReservationsIn, singularBooking, options,
  } = reservationAction;
  try {
    let response;
    if (reservations[0].type === 'SUBSCRIPTION') {
      const reqBody = {
        subscriptionReservation: reservations,
        cancelSubscription: cancelReservationsIn,
      };
      response = yield call(
        refundSubscriptionServices,
        companyId,
        reqBody,
      );
    } else {
      const reqBody = {
        reservations,
        cancelReservations: cancelReservationsIn,
      };
      response = yield call(
        cancelReservationsServices,
        companyId,
        reqBody,
      );
    }
    if (!response.error) {
      yield put(
        actions.cancelReservationsSuccess(
          response.data,
        ),
      );
      if (!singularBooking && reservations[0].type !== 'EVENT') {
        yield put(bookingsActions.requestBookingsAndSubscriptions(options));
      }
    } else { throw response; }
  } catch (error) {
    yield call(handleSagaError, error, 'cancelReservationsSaga');
    yield put(
      actions.cancelReservationsFailure(
        error.data, error.error,
      ),
    );
  } finally {
    yield put(calendarActions.getCalendarUsage());
  }
}

// CREATE
function* createManagerAllocationsSaga(companyId) {
  const state = yield select();

  const {
    facilities, reservation, timetable, booking,
  } = state;
  let { selectedTimeslots } = timetable;
  if (!selectedTimeslots.length > 0) {
    return;
  }

  const { selectedFacility } = facilities;
  const {
    userDetailsForReservation, selectedAgeGroup, selectedEvent,
  } = reservation;
  const { selectedPitchSplit } = booking;
  const { combineSlots } = selectedFacility;

  const facilityId = selectedFacility.id;
  if (combineSlots) {
    selectedTimeslots = getCombinedSlots(state, true);
    selectedTimeslots = selectedTimeslots.map((timeslot) => ({
      ...timeslot,
      facilityId,
    }));
  }
  const ageGroupBookingModifierId = selectedAgeGroup;
  const eventBookingModifierId = selectedEvent;
  const userDetails = userDetailsForReservation;
  const userEmailFor = userDetailsForReservation.email;

  const allocationsToCreate = selectedTimeslots.map((selectedTimeslot) => ({
    startTime: selectedTimeslot.startTime,
    endTime: selectedTimeslot.endTime,
    pitchSplit: selectedPitchSplit,
    facilityId: selectedTimeslot.facilityId,
    subFacilityId: selectedTimeslot.subFacilityId,
  }));

  const response = yield call(
    createManagerAllocationsService,
    companyId,
    facilityId,
    allocationsToCreate,
    userEmailFor,
    userDetails,
    ageGroupBookingModifierId,
    eventBookingModifierId,
  );

  if (response.error) {
    yield put(
      basketActions.failAllocationsCreation(
        response.error,
        response.erroredAllocations,
      ),
    );
    return;
  }

  if (!response.error) {
    yield put(basketActions.succeedAllocationsCreation());
    yield put(basketActions.requestBasketRetrieval());
  }

  const allocationCreationResponseData = response.data;

  if (allocationCreationResponseData
    && allocationCreationResponseData.erroredAllocations.length > 0
  ) {
    yield put(
      basketActions.partiallyFailAllocationsCreation(
        allocationCreationResponseData.erroredAllocations,
      ),
    );
  }

  yield put(resetTimetableData());
}

function* fetchReservationSelectedRow(companyId, action) {
  const state = yield select();
  const { id, bookingType } = action;
  const { selectedRows } = state.bookings;
  const response = yield call(
    bookingType === 'RESERVATION'
      ? getBookingService
      : getSubscriptionService,
    companyId, id,
  );

  if (response.error) {
    throw response.error;
  }

  const data = response.data[0];
  let selectedRowShape;

  if (bookingType === 'RESERVATION') {
    selectedRowShape = {
      ...data,
      slot: data.startTime,
      id: data.id
        ? data.id
        : data.subscriptionId,
      userId: data.user.id,
      allocations: data.allocations.concat(
        data.amenities,
      ),
    };
  } else {
    const { slot } = selectedRows[0];
    const { data } = response;

    const slotOrders = data.orders?.filter((x) => x.subscription_order.slot === slot);
    const partialPaymentOrders = data.partialPaymentOrders?.filter((x) => x.subscription_order.slot === slot);

    selectedRowShape = {
      ...data,
      orders: slotOrders,
      partialPaymentOrders,
      paid: slotOrders?.some((x) => !!x.paidAt && x.status === 'CREATED') ?? false,
      slot,
      userId: data.user.id,
      total: data.orders[0].total / 100,
      totalExTax: data.orders[0].totalExTax,
      tax: data.orders[0].tax,
      type: 'SUBSCRIPTION',
    };
  }

  yield put(bookingsActions.updateSelectedRowsPartialPayment(selectedRowShape));
}

function* updateCalendarSelectedSlotDurationSaga() {
  const state = yield select();
  const { selectedTimeslots } = state.timetable;
  const { userId } = state.currentUser.currentUser;
  if (selectedTimeslots.length === 0) {
    return;
  }
  // if change the duration of a calendar selected slot update the slot time to the new duration if it is available
  if (selectedTimeslots.length === 1 && selectedTimeslots[0].calendarSelected) {
    const timeslot = selectedTimeslots[0];
    const { selectedDuration } = state.reservation;

    if (selectedDuration > 60) {
      const {
        startTime, facilityId, subFacilityId,
      } = timeslot;
      const [availabilityResponse] = yield all([
        call(getUserTimeTableslotsService, {
          facilityId,
          pitchSplit: 1,
          dayRange: 7,
          searchDate: Moment(startTime).clone().utc(true).format('YYYY-MM-DD'),
          subFacilityId: subFacilityId || null,
          manager: true,
          selectedDuration,
          userEmail: '',
          userId, // this is managers userId as user is not yet selected needed to allow manager availability check
        }),
      ]);
      const dayAvailability = availabilityResponse.data.availableTimeSlotsForWeekByDay[Moment(startTime).format('dddd')];
      const slot = dayAvailability.find((slot) => slot.startTime === startTime);
      if (slot?.unavailable) {
        yield put(sessionActions.updateErrorSnackbar('Timeslot is unavailable', true));
        yield put(actions.updateIsReservationCreationFromCalendar(false));
        yield put(calendarActions.updateReservationCreationLoading(false));
        yield put(actions.updateSelectedDuration(60));
        return;
      }

      const updatedTimeslot = {
        startTime,
        endTime: Moment(startTime).clone().add(selectedDuration, 'minutes').subtract(1, 's')
          .format(),
        unavailable: false,
        facilityId,
        subFacilityId,
        calendarSelected: true,
      };

      yield put(updateTimetableSelectedTimeslots(updatedTimeslot));
      yield put(updateTimetableSelectedTimeslots(updatedTimeslot));
    }
  }
}

function* toggleManagerCalendarReservations(_, { event }) {
  const { facility, subfacility } = event;
  let { time } = event;
  const state = yield select();
  const { userId } = state.currentUser.currentUser;

  // Clear the previous state
  yield all([
    put(succeedTimetablePriceRetrieval(0)),
    put(facilitiesActions.resetSelectedFacilityStore()),
    put(resetTimetableData()),
    put(actions.updateIsReservationCreationFromCalendar(true)),
    put(calendarActions.updateReservationCreationLoading(true)),
  ]);

  // If there are defined start times; find the nearest one
  if (facility?.definedStartTimes && facility.definedStartTimes.length > 0) {
    const selectedTime = time.clone();
    const nearestTime = findNearestDefinedStartTime(selectedTime, facility.definedStartTimes);

    // Update the time to the nearest defined start time
    time = time.set({
      hour: nearestTime.hour(),
      minute: nearestTime.minute(),
      second: 0,
    });
  }

  let duration = 60; // Default to 60

  if (facility?.allowedDurations && facility.allowedDurations.length > 0) {
    if (facility.allowedDurations.includes(60)) {
      duration = 60;
    } else {
      duration = Math.min(...facility.allowedDurations);
    }
  } else if (facility?.minDuration > 60) {
    duration = facility.minDuration;
  }
  const startTime = time.clone();
  const endTime = time.clone().add(duration, 'minutes').subtract(1, 's');

  const timeslot = {
    startTime: startTime.format(),
    endTime: endTime.format(),
    unavailable: false,
    facilityId: facility.id,
    subFacilityId: subfacility?.id,
    calendarSelected: true,
  };

  // Perform availability check and price retrieval concurrently
  const [availabilityResponse, priceResponse] = yield all([
    call(getUserTimeTableslotsService, {
      facilityId: facility.id,
      pitchSplit: 1,
      dayRange: 1,
      searchDate: startTime.clone().utc(true).format('YYYY-MM-DD'),
      subFacilityId: subfacility?.id || null,
      manager: true,
      selectedDuration: duration,
      userEmail: '',
      userId, // this is managers userId as user is not yet selected needed to allow manager availability check
    }),
    call(
      getTimetableSelectedTimeSlotsPrice,
      facility.companyId,
      [timeslot],
      null,
      null,
      1,
      '',
      true,
    ),
  ]);

  if (!availabilityResponse.data) {
    yield put(sessionActions.updateErrorSnackbar('Failed to check availability', true));
    yield put(actions.updateIsReservationCreationFromCalendar(false));
    yield put(calendarActions.updateReservationCreationLoading(false));
    return;
  }

  const dayAvailability = availabilityResponse.data.availableTimeSlotsForWeekByDay[startTime.format('dddd')];

  const slot = dayAvailability.find((slot) => slot.startTime === startTime.format());

  if (slot?.unavailable) {
    yield put(sessionActions.updateErrorSnackbar('Timeslot is unavailable', true));
    yield put(actions.updateIsReservationCreationFromCalendar(false));
    yield put(calendarActions.updateReservationCreationLoading(false));
    return;
  }

  if (priceResponse.error) {
    yield put(sessionActions.updateErrorSnackbar('Failed to get price', true));
    yield put(actions.updateIsReservationCreationFromCalendar(false));
    yield put(calendarActions.updateReservationCreationLoading(false));
    return;
  }

  yield all([
    put(succeedTimetablePriceRetrieval(priceResponse.data)),
    put(actions.toggleManagerReservationsCreationPopup()),
    put(facilitiesActions.updateSelectedFacility(facility)),
    put(updateTimetableSelectedTimeslots(timeslot)),
    put(changeTimetableSelectedDate(startTime.clone().utc(true).format('YYYY-MM-DD'))),
    put(calendarActions.updateReservationCreationLoading(false)),
    ...(subfacility ? [put(facilitiesActions.updateSelectedSubFacility(subfacility))] : []),
  ]);
}

function* convertReservationToBlockBooking() {
  const state = yield select();
  const combinedTimeslots = combineTimeslots(state.timetable.selectedTimeslots);

  if (combinedTimeslots.length > 1) {
    yield put(sessionActions.updateErrorSnackbar('Cannot convert multiple timeslots to a block booking', true));
    return;
  }

  const selectedTimeslot = combinedTimeslots[0];

  // Close the current dialog and open the subscription dialog
  yield put(actions.toggleManagerReservationsCreationPopup());
  // Clear the reservation data
  yield all([
    put(succeedTimetablePriceRetrieval(0)),
    put(resetTimetableData()),
  ]);

  // Open the subscription dialog
  yield put(subscriptionsActions.toggleSubscriptionCreationPopup());

  // Move data over to the subscription dialog
  yield put(subscriptionsActions.updateSubscription({
    facility: state.facilities.selectedFacility,
    user: state.reservation.userDetailsForReservation,
    accessRestriction: { exclusions: [] },
  }));

  const startTime = Moment.parseZone(selectedTimeslot.startTime);
  const endTime = Moment.parseZone(selectedTimeslot.endTime);

  yield put(subscriptionsActions.updateSubscriptionStore({
    amount: Number(state.timetable.priceForSlots),
    facilityId: state.timetable.subFacilityId ?? state.timetable.facilityId,
    startTimeFormatted: startTime,
    endTimeFormatted: endTime,
    user: state.reservation.userDetailsForReservation,
    validFrom: startTime.clone().startOf('day'),
    weekday: startTime.clone().weekday(),
  }));
  yield put(subscriptionsActions.updateSubscriptionUserDetails(state.reservation.userDetailsForReservation));
}

export function* createManagerAllocationsWatcher(companyId) {
  yield takeEvery(
    basketActions.ALLOCATIONS_CREATION_REQUESTED,
    createManagerAllocationsSaga,
    companyId,
  );
}

export function* createManagerReservationWatcher(companyId) {
  yield takeEvery(actions.RESERVATIONS_CREATION_REQUESTED, createManagerReservationSaga, companyId);
}

export function* updateReservationWatcher(companyId) {
  yield takeEvery(actions.UPDATE_RESERVATION, updateReservationSaga, companyId);
}

export function* recordMultiplePaymentWatcher(companyId) {
  yield takeEvery(
    actions.RECORD_MULTIPLE_RESERVATION_PAYMENT, recordMulitplePaymentSaga, companyId,
  );
}

export function* cancelReservationsWatcher(companyId) {
  yield takeEvery(actions.CANCEL_RESERVATIONS, cancelReservationsSaga, companyId);
}

export function* retrospectiveReservationsWatcher() {
  yield takeEvery(actions.RETROSPECTIVE_BOOKING_UPDATED, retrieveTimetableAvailabilitySaga);
}

export function* fetchReservationSelectedRowWatcher(companyId) {
  yield takeEvery(actions.FETCH_RESERVATION_SELECTED_ROW, fetchReservationSelectedRow, companyId);
}

export function* toggleManagerCalendarReservationsWatcher(event) {
  yield takeEvery(actions.MANAGER_CALENDAR_RESERVATIONS_TOGGLED, toggleManagerCalendarReservations, event);
}

export function* convertReservationToBlockBookingWatcher() {
  yield takeEvery(actions.CONVERT_RESERVATION_TO_BLOCK_BOOKING, convertReservationToBlockBooking);
}

export function* updateCalendarSelectedSlotDurationWatcher() {
  yield takeEvery(actions.DURATION_SELECTION_UPDATED, updateCalendarSelectedSlotDurationSaga);
}
