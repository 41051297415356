import { useSelector } from 'react-redux';

const checkProductPermission = (product, companyProducts) => companyProducts[product] === 'ENABLED';

const useCompanyProduct = (product) => {
  const { products } = useSelector((state) => state.companies.companyInfo);
  return checkProductPermission(product, products);
};

export default useCompanyProduct;
