import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import * as facilitiesActions from '../../../reducers/facilitiesReducer';
import ConfigurationTabs from './editFacility/ConfigurationTabs';
import FacilityInternalToggle from './FacilityInternalToggle';
import { usePitchbookingUser, useOrigin } from '../../../hooks';

const EditFacility = ({
  match,
  history,
}) => {
  const dispatch = useDispatch();
  const { isPitchbookingManager } = usePitchbookingUser();
  const { bookerUrl } = useOrigin();

  const [tabIndex, setTabIndex] = useState(0);

  const { isPowerleague } = useSelector((state) => state.companies.companyInfo);
  const facilities = useSelector((state) => state.facilities);

  const {
    updateFacililitySubmitting: isSubmitting,
    updateFacilitySuccess: isSuccess,
    selectedFacility,
  } = facilities;

  const requestFacility = (id) => dispatch(facilitiesActions.requestFacilityRetrieval(id));
  const updateSelectedFacilityStore = (id) => dispatch(
    facilitiesActions.updateSelectedFacilityStore(id),
  );
  const requestSelectedFacilityUpdate = (facilityId, facilityData) => dispatch(
    facilitiesActions.requestSelectedFacilityUpdate(facilityId, facilityData),
  );
  const resetSelectedFacilityStore = () => dispatch(facilitiesActions.resetSelectedFacilityStore());
  const resetUpdateRequest = () => dispatch(facilitiesActions.resetUpdateRequest());

  const updateFacility = (id) => {
    requestSelectedFacilityUpdate(id, {
      ...facilities.selectedFacility,
    });
  };

  const cancelEditFacility = () => {
    history.push('/facilities');
    resetSelectedFacilityStore();
  };

  useEffect(() => {
    if (match.path === '/facilities/edit/:facilityid') {
      updateSelectedFacilityStore({});
      requestFacility(match.params.facilityid);
    }
  }, [match]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/facilities');
    }
  }, [isSuccess]);

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return () => {
      resetUpdateRequest();
    };
  }, []);

  if (!selectedFacility) return null;

  const {
    id, name, sport,
  } = selectedFacility;
  const nameInvalid = name === '';
  const sportInvalid = selectedFacility.type !== 'AMENITY' && sport?.length === 0;
  const error = nameInvalid || sportInvalid;

  const [hideActionButtons, setHideActionButtons] = useState([]);
  useEffect(() => {
    if (selectedFacility.type === 'AMENITY') {
      setHideActionButtons([0, 1, 2]);
    } else {
      setHideActionButtons([0, 4, 5, 6]);
    }
  }, [selectedFacility]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <ConfigurationTabs
          facility={selectedFacility}
          pbUser={isPitchbookingManager}
          handleTabChange={(selectedTab) => setTabIndex(selectedTab)}
          reloadResource={() => requestFacility(selectedFacility.id)}
        />
      </div>

      {!hideActionButtons.includes(tabIndex) && (
      <div style={{ display: 'flex' }}>
        <Button
          disabled={error || isSubmitting}
          variant="contained"
          color="primary"
          style={{
            marginLeft: '10px', marginTop: '10px', marginBottom: '10px', width: 200,
          }}
          onClick={() => updateFacility(id)}
        >
          Save
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: '10px', marginTop: '10px', marginBottom: '10px', width: 200,
          }}
          onClick={() => cancelEditFacility()}
        >
          Cancel Changes
        </Button>
        <AlternativelyVisible condition={isPowerleague}>
          <FacilityInternalToggle />
          <a href={`${bookerUrl}/book/facility/${selectedFacility.id}`} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              color="secondary"
              style={{
                marginLeft: '10px', marginTop: '10px', marginBottom: '10px', width: 200,
              }}
            >
              View Booking Page
            </Button>
          </a>
        </AlternativelyVisible>
      </div>
      )}
    </>
  );
};

EditFacility.propTypes = {
  match: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default EditFacility;
