/* eslint-disable react/prop-types */
import { Typography } from '@material-ui/core';
import React from 'react';
import { Markdown } from '../../../../../helpers/Markdown';

export const Summary = ({ billing }) => (
  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
    <Typography style={{ fontSize: '1.25rem', fontWeight: 800 }}>
      Agreement Summary
    </Typography>

    <Typography style={{ color: 'rgb(75, 85, 99)', fontWeight: 600 }}>
      {`Pitchbooking Ltd will host the ${billing?.company?.name} listings on pitchbooking.com 
        for them to be booked and paid for in real-time by the public. 
        In addition, nominated administrators from ${billing?.company?.name} 
        will get access to the Pitchbooking Manager Dashboard in order to facilitate and 
        monitor bookings and payments.`}
    </Typography>

    <div style={{ borderLeft: '5px solid rgb(var(--primary))', paddingLeft: '1.25rem' }}>
      <Typography style={{ fontSize: '1.125rem', color: 'rgb(75, 85, 99)', fontStyle: 'italic' }}>
        <Markdown>
          {billing.note}
        </Markdown>
      </Typography>
      <Typography style={{ fontSize: '1.125rem', color: 'rgb(75, 85, 99)', fontStyle: 'italic' }}>
        - Lyndon McCullough
      </Typography>
    </div>
  </div>
);
