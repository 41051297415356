/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from 'react';
import { Typography, Button } from '@material-ui/core';
import { SaveAltTwoTone, ShareTwoTone } from '@material-ui/icons';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import { useDispatch } from 'react-redux';
import EditBillingDialog from '../../editBillingDialog';
import { usePitchbookingUser } from '../../../../hooks';
import { updateBilling } from '../../../../reducers/companiesReducer';
import { ShareModel } from '../../../../components/ShareModel/ShareModel';
import AddTeamMemberDialog from '../../../teamMembers/components/AddTeamMemberDialog';

export const AgreementHeader = ({ billing, isMobile }) => {
  const dispatch = useDispatch();
  const { id: billingId } = billing;
  const { company } = billing;
  const { name: companyName, logoUrl, currency } = company;
  const { isPitchbookingManager } = usePitchbookingUser();
  const currencySym = getCurrencySymbol(currency);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{
        display: 'flex',
        flexDirection: !isMobile ? 'row' : 'column',
        gap: '1rem',
        flex: 5,
        alignItems: 'center',
        textAlign: isMobile ? 'center' : 'left',
      }}
      >
        <img src={logoUrl} alt="Company Logo" style={{ height: '7rem' }} />
        <div>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            {`${companyName}`}
          </Typography>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            Pitchbooking Ltd
          </Typography>
          <Typography variant="body1" style={{ color: 'rgb(75, 85, 99)', fontWeight: 600 }}>
            Partnership Agreement
          </Typography>
        </div>

        {/* Download / Share Buttons  */}
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          alignItems: 'flex-end',
          justifyContent: 'center',
          flex: 1,
        }}
        >
          <div style={{ display: 'flex', gap: '1rem' }}>
            {/* <Button
            variant="outlined"
            style={{ color: 'rgb(var(--primary))', fontWeight: 600 }}
          >
            <div style={{ display: 'flex', alignContent: 'center', gap: '0.5rem' }}>
              <SaveAltTwoTone />
              Download PDF
            </div>
          </Button>
          */}

            <AddTeamMemberDialog title="Add Finance Officer" isAgreementPage />

            <ShareModel
              buttonText="Share Agreement"
              title="Share Agreement"
              description="Copy the link below to share this agreement with your finance officer"
              copyUrl={`https://pitchbooking.com/agreement/${billingId}`}
            />

            {isPitchbookingManager && (
            <EditBillingDialog
              billing={billing}
              currencySym={currencySym}
              updateBilling={(billingParams) => dispatch(updateBilling(billingId, billingParams))}
              isBillingPage
            />
            )}
          </div>
        </div>
      </div>

    </div>
  );
};
