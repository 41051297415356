import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as actions from '../../reducers/companiesReducer';
import * as companyServices from '../../services/companiesServices';
import { Agreement } from './_v1';
import { usePitchbookingUser } from '../../hooks';
import LoadingSection from '../../components/LoadingComponents/LoadingSection';

const v1AgreementPage = ({ match }) => {
  const dispatch = useDispatch();
  const currentUser = usePitchbookingUser();
  const { id: userId, isPitchbookingManager } = currentUser;
  const billing = useSelector((state) => state.companies.billing);
  const companyInfo = useSelector((state) => state.companies.companyInfo);
  const billingCreationStatus = useSelector((state) => state.companies.billingCreationStatus);
  const [isLoading, setIsLoading] = useState(false);

  const getAgreementData = async (billingId) => {
    try {
      setIsLoading(true);
      await dispatch(actions.getBillingData(billingId));
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const { billingId } = match.params;

    getAgreementData(billingId);

    if (companyInfo?.id && !isPitchbookingManager) {
      const data = {
        billingId,
        viewedByUserId: userId,
        timeViewed: moment().format('YYYY-MM-DD HH:mm:ss'),
      };

      companyServices.trackCompanyBilling(companyInfo?.id, data);
    }
  }, []);

  return (
    <>
      {isLoading
        ? <LoadingSection loadingText="Loading agreement please wait" />
        : (
          billing && billing.company && (
          <Agreement
            billing={billing}
            billingCreationStatus={billingCreationStatus}
          />
          )
        )}
    </>
  );
};

v1AgreementPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      billingId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default v1AgreementPage;
