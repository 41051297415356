import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DateRangePicker } from 'react-dates';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import * as accessRestrictionsActions from '../../../../reducers/accessRestrictionsReducer';
import * as companyActions from '../../../../reducers/companiesReducer';
import MenuHeading from '../../../../components/MenuHeading';
import AccessRetrictionRecurrencePicker from './AccessRetrictionRecurrencePicker';
import ToggleButtons from '../../../../components/ToggleButtons';
import AccessRestrictionsDayPicker from './AccessRestrictionsDayPicker';
import AccessRestrictionsDialogActions from './AccessRestrictionsDialogActions';
import AccessRestrictionsDialogFacilityPicker from './AccessRestrictionsDialogFacilityPicker';
import AccessRestrictionsDialogTimePicker from './AccessRestrictionsDialogTimePicker';
import AccessRestrictionsDialogRecurringValidityPicker from './AccessRestrictionsDialogRecurringValidityPicker';
import AccessRestrictionsDialogMembershipPicker from './AccessRestrictionsDialogMembershipPicker';
import AccessRestrictionsExclusionsPicker from './AccessRestrictionsExclusionsPicker';
import CloseDialogIcon from '../../../../shared-components/CloseDialogIcon';

import '../../../../styles/accessRestriction.css';
import 'rc-time-picker/assets/index.css';

class AccessRestrictionsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      arTypeTitle: 'Access Restriction',
    };
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  componentDidMount() {
    const pathname = window.location.pathname.substring(1);

    if (pathname === 'closures') {
      this.setState({ arTypeTitle: 'Closure' });
    } else if (pathname === 'priorityaccess') {
      this.setState({ arTypeTitle: 'Priority Access' });
    }
  }

  onFocusChange() {
    this.setState({ focused: true });
  }

  handleClickOpen = () => {
    const { updateAccessRestrictionDialog, getMemberships } = this.props;
    getMemberships();
    updateAccessRestrictionDialog(true);
  };

  handleRequestClose = () => {
    const {
      toggleEditing,
      resetAccessRestrictionCreation,
      updateAccessRestrictionDialog,
    } = this.props;
    toggleEditing(false);
    resetAccessRestrictionCreation();
    updateAccessRestrictionDialog();
  };

  createAccessRestriction = () => {
    const { createAccessRestriction, getOptions } = this.props;
    createAccessRestriction(getOptions);
    this.handleRequestClose();
  }

  handleSave = (id) => {
    const { saveAccessRestriction, toggleEditing, getOptions } = this.props;
    saveAccessRestriction(id, 'SAVE', getOptions);
    toggleEditing(false);
    this.handleRequestClose();
  }

  render() {
    const {
      accessRestrictions,
      accessRestrictionDialogOpen,
      updateAccessRestrictionCreation,
      ARtitle,
    } = this.props;

    const {
      newAccessRestriction,
      editingAccessRestriction,
    } = accessRestrictions;

    const title = !editingAccessRestriction ? `New ${ARtitle}` : `Edit ${ARtitle}`;

    let formattedEndTime = moment(newAccessRestriction.endTime);

    if (formattedEndTime.format().includes('59')) {
      formattedEndTime = moment(newAccessRestriction.endTime).endOf('minute').add(1, 'seconds').format();
    }

    const formattedAccessRestriction = {
      ...newAccessRestriction,
      endTime: formattedEndTime,
    };

    const {
      recurrence, validFrom, validTo, description, type,
    } = formattedAccessRestriction;

    const weekly = (recurrence === 'WEEKLY');
    const oneTime = (recurrence === 'NONE');
    const daily = (recurrence === 'DAILY');
    const always = (recurrence === 'ALWAYS');

    const { focused, focusedInput } = this.state;

    const accessRightsButtonsData = [{
      buttonTitle: 'Closure',
      buttonValue: 'HOLIDAY',
    },
    {
      buttonTitle: 'Membership',
      buttonValue: 'MEMBER_ACCESS',
    }];

    const isMobile = window.innerWidth < 768;

    const { arTypeTitle } = this.state;
    return (
      <div>
        <div>
          <Button
            onClick={this.handleClickOpen}
            variant="contained"
            color="primary"
            style={{ width: 250 }}
          >
            {title}
          </Button>
        </div>
        <Dialog
          fullWidth
          maxWidth="md"
          open={accessRestrictionDialogOpen}
          onBackdropClick={this.handleRequestClose}
          onEscapeKeyDown={this.handleRequestClose}
          fullScreen={isMobile}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle>{title}</DialogTitle>
            <DialogActions variant="none">
              <CloseDialogIcon onClick={() => this.handleRequestClose()} />
            </DialogActions>
          </div>
          <DialogContent>
            <AccessRestrictionsDialogFacilityPicker />
            <MenuHeading title={`${arTypeTitle} name`} />
            <TextField
              id="description"
              className="textBox"
              type="text"
              value={description}
              onChange={(event) => updateAccessRestrictionCreation(
                { description: event.target.value },
              )}
            />
            <MenuHeading title={`When do you want the ${arTypeTitle} to apply?`} />
            <ConditionallyVisible condition={editingAccessRestriction}>
              <>
                <Typography color="secondary">Selecting one of these options will clear any prefilled information</Typography>
                <Typography color="secondary">If you click `CANCEL` no changes will be saved.</Typography>
              </>
            </ConditionallyVisible>
            <AccessRetrictionRecurrencePicker arTypeTitle={arTypeTitle?.toLowerCase()} />
            <AlternativelyVisible condition={recurrence === null}>
              <div>Please select a recurrence.</div>
              <div>
                <ConditionallyVisible condition={!always}>
                  <AccessRestrictionsDialogTimePicker />
                </ConditionallyVisible>
                <ConditionallyVisible condition={oneTime}>
                  <div>
                    <MenuHeading title="Date Period:" />
                    <DateRangePicker
                      startDateId="accessRestrictionStartTime"
                      endDateId="accessRestrictionEndTime"
                      startDate={validFrom !== null ? moment(validFrom) : validFrom}
                      endDate={validTo !== null ? moment(validTo) : validTo}
                      onDatesChange={({ startDate, endDate }) => updateAccessRestrictionCreation({
                        validFrom: startDate,
                        validTo: endDate,
                      })}
                      focusedInput={focusedInput}
                      onFocusChange={(newFocusedInput) => this.setState(
                        { focusedInput: newFocusedInput },
                      )}
                      minimumNights={0}
                      numberOfMonths={1}
                      displayFormat="ddd, DD-MMM-YYYY"
                      noBorder
                    />
                  </div>
                </ConditionallyVisible>
                <ConditionallyVisible condition={weekly}>
                  <div>
                    <MenuHeading title="Select day:" />
                    <AccessRestrictionsDayPicker />
                  </div>
                </ConditionallyVisible>

                <ConditionallyVisible condition={weekly || daily}>
                  <AccessRestrictionsDialogRecurringValidityPicker
                    onFocusChange={this.onFocusChange}
                    focused={focused}
                    arTypeTitle={arTypeTitle}
                  />
                </ConditionallyVisible>
                <ConditionallyVisible condition={weekly || daily}>
                  <AccessRestrictionsExclusionsPicker />
                </ConditionallyVisible>
                <MenuHeading title="Which type of restriction?" />
                <ToggleButtons
                  buttonsData={accessRightsButtonsData}
                  changeOption={(option) => updateAccessRestrictionCreation(
                    { type: option, memberships: [] },
                  )}
                  value={newAccessRestriction.type}
                />
                <ConditionallyVisible condition={type === 'MEMBER_ACCESS'}>
                  <AccessRestrictionsDialogMembershipPicker />
                </ConditionallyVisible>
                <MenuHeading title="Live Now?" />
                <div style={{ display: 'flex' }}>
                  <Switch
                    checked={newAccessRestriction.status === 'ACTIVE'}
                    value={newAccessRestriction.status === 'ACTIVE'}
                    onChange={(event) => updateAccessRestrictionCreation(
                      { status: event.target.checked ? 'ACTIVE' : 'INACTIVE' },
                    )}
                  />
                </div>
              </div>

            </AlternativelyVisible>
          </DialogContent>
          <DialogActions>
            <AccessRestrictionsDialogActions
              handleRequestClose={this.handleRequestClose}
              handleSave={this.handleSave}
              createAccessRestriction={this.createAccessRestriction}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AccessRestrictionsDialog.propTypes = {
  accessRestrictions: PropTypes.shape().isRequired,
  getOptions: PropTypes.shape().isRequired,
  createAccessRestriction: PropTypes.func.isRequired,
  accessRestrictionDialogOpen: PropTypes.bool,
  saveAccessRestriction: PropTypes.func.isRequired,
  getMemberships: PropTypes.func.isRequired,
  updateAccessRestrictionCreation: PropTypes.func.isRequired,
  updateAccessRestrictionDialog: PropTypes.func.isRequired,
  resetAccessRestrictionCreation: PropTypes.func.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  ARtitle: PropTypes.string.isRequired,
};

AccessRestrictionsDialog.defaultProps = {
  accessRestrictionDialogOpen: false,
};

const mapStateToProps = (state) => ({
  accessRestrictions: state.accessRestrictions,
  accessRestrictionDialogOpen: state.accessRestrictions.accessRestrictionDialogOpen,
  timezone: state.companies.companyInfo.timezone,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  createAccessRestriction: (getOptions) => accessRestrictionsActions.createAccessRestriction(
    getOptions,
  ),
  resetAccessRestrictionCreation: () => accessRestrictionsActions.resetAccessRestrictionCreation(),
  saveAccessRestriction: (
    accessRestrictionId, buttonAction, getOptions,
  ) => accessRestrictionsActions.saveAccessRestriction(accessRestrictionId,
    buttonAction, getOptions),
  updateAccessRestrictionCreation:
    (keyWithValue) => accessRestrictionsActions.updateAccessRestrictionCreation(keyWithValue),
  updateAccessRestrictionDialog:
    (keyWithValue) => accessRestrictionsActions.updateAccessRestrictionDialog(keyWithValue),
  toggleEditing: (bool) => accessRestrictionsActions.toggleEditing(bool),
  getMemberships: () => dispatch(companyActions.requestCompanyMembershipRetrieval()),
}, dispatch);

export default connect(
  mapStateToProps, mapDispatchToProps,
)(AccessRestrictionsDialog);
