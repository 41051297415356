import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import AlternativelyVisible from '@pitchbooking-dev/pb-shared/lib/components/alternativelyVisible';
import moment from 'moment';
import InvoicesTable from './InvoicesTable';
import * as actions from '../../reducers/invoicesReducer';
import InvoiceCreationDialog from './InvoiceCreationDialog';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import ToggleButtons from '../../components/ToggleButtons';
import ActionBar from '../../components/ActionBar';
import InvoiceSummary from './invoiceSummary';

class Invoices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceDisplay: 'UNPAID',
      selectedMonth: moment(),
    };
  }

  componentDidMount() {
    const {
      getCompanyInvoices,
    } = this.props;
    getCompanyInvoices('UNPAID');
  }

  updateCheck = (value, selectedMonth = moment()) => {
    if (value !== null) {
      this.setState({
        invoiceDisplay: value,
        selectedMonth,
      }, () => {
        const { getCompanyInvoices } = this.props;
        getCompanyInvoices(value, selectedMonth);
      });
    }
  }

  render() {
    const {
      invoices, currency, timezone, invoicesLoading,
    } = this.props;
    const { invoiceDisplay, selectedMonth } = this.state;
    const currencySym = getCurrencySymbol(currency);

    const ARDisplayButtonsData = [{
      buttonTitle: 'Unpaid',
      buttonValue: 'UNPAID',
    },
    {
      buttonTitle: 'Paid',
      buttonValue: 'PAID',
    },
    {
      buttonTitle: 'Voided',
      buttonValue: 'VOID',
    },
    ];

    return (
      <div style={{ background: '#fafbff', margin: '-20px', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <ActionBar>
            <InvoiceCreationDialog />
            <div style={{ marginLeft: '100px' }}>

              <ToggleButtons
                buttonsData={ARDisplayButtonsData}
                changeOption={(option) => this.updateCheck(option, selectedMonth)}
                value={invoiceDisplay}
                style={{ flexWrap: 'nowrap' }}
              />
            </div>
          </ActionBar>

          <InvoiceSummary invoices={invoices} currencySym={currencySym} />
        </div>

        <div style={{ margin: 40, fontSize: '4vh' }} />
        <AlternativelyVisible condition={invoicesLoading}>
          <LoadingSkeletonDataTable />
          <InvoicesTable
            invoices={invoices}
            currencySym={currencySym}
            timezone={timezone}
            invoiceDisplay={invoiceDisplay}
            selectedMonth={selectedMonth}
            updateCheck={this.updateCheck}
          />
        </AlternativelyVisible>
        <div style={{ margin: 40 }} />
      </div>
    );
  }
}

Invoices.propTypes = {
  getCompanyInvoices: PropTypes.func.isRequired,
  invoices: PropTypes.shape(),
  currency: PropTypes.string,
  timezone: PropTypes.string,
  invoicesLoading: PropTypes.bool,
};

Invoices.defaultProps = {
  invoices: [],
  currency: 'gbp',
  timezone: 'Europe/London',
  invoicesLoading: false,
};

const mapStateToProps = (state) => ({
  invoices: state.invoices.invoices,
  invoicesLoading: state.invoices.invoicesLoading,
  currency: state.companies.companyInfo.currency,
  timezone: state.companies.companyInfo.timezone,
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyInvoices: (status, selectedMonth) => dispatch(
    actions.getCompanyInvoices(status, selectedMonth),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
