/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { LocalOfferTwoTone, HighlightOffTwoTone } from '@material-ui/icons/';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { Formik, Form } from 'formik';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks';
import { updateCompanyServices } from '../../../services/companiesServices';
import { getBillingData } from '../../../reducers/companiesReducer';
import { OverlayLoader } from '../../../components/OverlayLoader';

export const EditCommissionsDialog = ({ data }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { company, id: billingId } = data;
  const {
    id: companyId,
    name: companyName,
    eventsCommission: events,
    membershipCommission: membership,
    commission: facility,
  } = company;

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpen = () => setIsDialogOpen(true);
  const handleClose = () => setIsDialogOpen(false);

  const mutation = useMutation({
    mutationFn: async (newCommissions) => {
      const res = await updateCompanyServices(companyId, newCommissions);

      if (res.status !== 200) {
        throw new Error('Error updating commissions');
      }

      return res.data;
    },
    onSuccess: async () => {
      await dispatch(getBillingData(billingId)); // Refresh billing data
      toast.trigger({
        type: 'success',
        message: 'Commissions updated successfully',
      });
      setIsDialogOpen(false);
    },
    onError: () => {
      toast.trigger({
        type: 'error',
        message: 'Error updating commissions',
      });
    },
  });

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<LocalOfferTwoTone />}
        onClick={handleOpen}
      >
        Edit Commissions
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        {mutation.isLoading && <OverlayLoader />}

        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6" style={{ fontWeight: 600 }}>
              {`Edit Commissions for ${companyName}`}
            </Typography>
            <HighlightOffTwoTone onClick={handleClose} style={{ cursor: 'pointer' }} />
          </div>
        </DialogTitle>

        <Formik
          initialValues={{
            eventsCommission: events || 0,
            membershipCommission: membership || 0,
            commission: facility || 0,
          }}
          onSubmit={async (values) => {
            mutation.mutate(values);
          }}
        >
          {({
            values, handleSubmit, handleChange,
          }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '1rem', width: '200px', fontWeight: 500 }}>
                      Events:
                    </Typography>
                    <PBInput
                      id="eventsCommission"
                      type="percentage"
                      name="eventsCommission"
                      value={values.eventsCommission}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    />
                  </div>

                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '1rem', width: '200px', fontWeight: 500 }}>
                      Memberships:
                    </Typography>
                    <PBInput
                      id="membershipCommission"
                      type="percentage"
                      name="membershipCommission"
                      value={values.membershipCommission}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    />
                  </div>

                  <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <Typography style={{ fontSize: '1rem', width: '200px', fontWeight: 500 }}>
                      Facility:
                    </Typography>
                    <PBInput
                      id="commission"
                      type="percentage"
                      name="commission"
                      value={values.commission}
                      onChange={handleChange}
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>
              </DialogContent>

              <DialogActions>
                <div style={{ display: 'flex', gap: '1rem', padding: '0 1rem 0.5rem 1rem' }}>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    disabled={mutation.isLoading}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={mutation.isLoading}
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
