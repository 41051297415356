/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Typography, Card } from '@material-ui/core';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PriceBreakdownTable from '../../../priceBreakdownTable';
import { usePitchbookingUser } from '../../../../../hooks';
import PriceTable from '../../../priceTable';
import { updateBilling } from '../../../../../reducers/companiesReducer';

export const Details = ({ billing, isMobile }) => {
  const dispatch = useDispatch();
  const { isPitchbookingManager } = usePitchbookingUser();
  const { company, amount: billAmount, id: billingId } = billing;
  const {
    currency, country, eventsCommission, commission: facilityCommission, membershipCommission,
  } = company;
  const currencySym = getCurrencySymbol(currency);
  const taxRate = country === 'United Kinddom' ? 0.2 : 0;
  const offerValid = billing?.offerExpiresAt
    && moment().isBefore(moment(billing.offerExpiresAt).endOf('day'))
    && billing?.offerAmount
    && billing?.offerAmount < billing?.amount;
  const [breakdownOpen, setBreakdownOpen] = useState(false);

  const commissions = [
    { title: 'Events', amount: eventsCommission, text: 'commission per registration' },
    { title: 'Memberships', amount: membershipCommission, text: 'commission per registration' },
    { title: 'Facilities', amount: facilityCommission, text: 'commission per facility' },
    {
      title: 'Girls Only Events & Camps',
      amount: 'Free',
      text: 'via our Game On initiative',
    },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography style={{ fontSize: '1.25rem', fontWeight: 800 }}>
          Price Breakdown
        </Typography>
      </div>

      {billAmount > 0 && (
        <>
          {(billing?.externalFacilities || billing?.internalFacilities)
            && billing.status === 'PENDING' && (
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <PriceBreakdownTable
                  billing={billing}
                  currencySym={currencySym}
                  taxRate={taxRate}
                  isPBAdmin={isPitchbookingManager}
                  offerValid={offerValid}
                  offerAmount={billing.offerAmount}
                  offerExpiresAt={billing.offerExpiresAt}
                  updateBilling={(billingParams) => dispatch(
                    updateBilling(billingId, billingParams),
                  )}
                  open={breakdownOpen}
                  setOpen={setBreakdownOpen}
                />
              </div>
          )}

          {!breakdownOpen && (
            <PriceTable
              amount={billing.amount}
              currencySym={currencySym}
              taxRate={taxRate}
              offerValid={offerValid}
              offerAmount={billing.offerAmount}
              offerExpiresAt={billing.offerExpiresAt}
            />
          )}
        </>
      )}

      <div
        style={{
          display: 'grid',
          width: '100%',
          gap: '1rem',
          gridTemplateColumns: !isMobile ? 'repeat(4, 1fr)' : 'repeat(1, 1fr)',
          gridAutoRows: 'minmax(150px, auto)',
        }}
      >
        {commissions.map((commission) => (
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '1rem',
              borderRadius: '0.5rem',
              gap: '0.25rem',
            }}
          >
            <Typography style={{ fontSize: '1.125rem', fontWeight: 600 }}>
              {commission.title}
            </Typography>

            <Typography
              style={{ fontSize: '2rem', fontWeight: 700, color: 'rgb(var(--primary))' }}
            >
              {`${
                commission.amount === 'Free'
                  ? commission.amount
                  : `${(commission?.amount * 100).toFixed(2)}%`
              } `}
            </Typography>

            <Typography
              style={{
                fontSize: '0.8rem',
                fontWeight: 600,
                color: 'rgb(75, 85, 99)',
              }}
            >
              {commission.text}
            </Typography>
          </Card>
        ))}
      </div>
    </div>
  );
};
