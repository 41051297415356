/* eslint-disable react/prop-types */
import React from 'react';
import { Typography, Card } from '@material-ui/core';
import {
  CreditCard, CalendarToday, People, FlashOn, CheckCircleOutlined, DescriptionOutlined,
} from '@material-ui/icons';

const benefits = [
  { icon: CreditCard, text: 'No setup fees' },
  { icon: CalendarToday, text: 'No maintenance costs' },
  { icon: People, text: 'No training costs' },
  { icon: FlashOn, text: 'No ongoing costs' },
  { icon: CheckCircleOutlined, text: 'Dedicated account manager' },
  { icon: DescriptionOutlined, text: 'Month to month contract' },
];

export const Benefits = ({ isMobile }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '-webkit-fill-available',
  }}
  >
    <Typography style={{ fontSize: '1.25rem', fontWeight: 800 }}>
      Key Benefits
    </Typography>

    <div
      style={{
        display: 'grid',
        width: '100%',
        gap: '1rem',
        gridTemplateColumns: !isMobile ? 'repeat(3, 1fr)' : 'repeat(2, 1fr)',
        gridAutoRows: 'minmax(150px, auto)',
      }}
    >
      {benefits.map((benefit) => (
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            textAlign: 'center',
            borderRadius: '0.5rem',
            gap: '0.25rem',
          }}
        >
          <benefit.icon
            style={{
              fontSize: '2.75rem',
              color: 'rgb(var(--primary))',
            }}
          />
          <Typography style={{ fontSize: '1rem', fontWeight: 600 }}>
            {benefit.text}
          </Typography>
        </Card>
      ))}
    </div>
  </div>
);
