/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import { setAccountOwner } from '../../../reducers/companyUsersReducer';
import PitchbookingTeamMemberSelector from './PitchbookingTeamMemberSelector';

export const AccountManager = ({ companyInfo }) => {
  const dispatch = useDispatch();
  const [accountManager, setAccountManager] = useState('');

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (companyInfo.accountOwner) {
      setAccountManager(companyInfo.accountOwner);
    }
  }, [companyInfo]);

  return (
    <>
      <Typography style={{
        textDecoration: 'underline', display: 'flex', alignItems: 'center', gap: '0.5rem',
      }}
      >
        Account Manager
        <HelpTwoToneIcon
          onClick={() => setShowAlert(!showAlert)}
          style={{
            fontSize: '1.3rem', cursor: 'pointer', color: !showAlert ? 'var(--accent)' : '', marginBottom: '0.2rem',
          }}
        />
      </Typography>

      {showAlert && (
        <Alert severity="info">
          <Typography variant="body2" style={{ marginBottom: '0.5rem' }}>
            The
            <strong style={{ fontStyle: 'italic' }}>{' Introduction to Pitchbooking '}</strong>
            email will only be sent if account status is:
            <strong style={{ fontStyle: 'italic' }}>
              {' Onboarding, Account Manager Unassigned, or Pending. '}
            </strong>
          </Typography>
        </Alert>
      )}

      <PitchbookingTeamMemberSelector
        value={accountManager}
        onChange={setAccountManager}
        label="Account Manager"
      />
      <Button
        variant="contained"
        color="primary"
        type="button"
        style={{ width: '50%', alignSelf: 'end' }}
        onClick={() => { dispatch(setAccountOwner(accountManager)); }}
      >
        Save
      </Button>

      <Divider />
    </>
  );
};
