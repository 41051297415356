import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  ListItemText,
  Divider,
  ButtonGroup,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible';
import { priceTaxCalculator } from '@pitchbooking-dev/pb-shared/lib/helpers/calculationHelpers';

import UpdateIcon from '@material-ui/icons/Update';
import { Link } from 'react-router-dom';
import LoadingSkeletonDataTable from '../../components/LoadingComponents/LoadingSkeletonDataTable';
import SubscriptionStripeOrdersTable from './components/SubscriptionStripeOrdersTable';
import Back from '../../components/Back';
import { generateValidityText } from '../../helpers';
import ToolTip from '../../components/Tooltip';
import { MaterialTable } from '../../components/MaterialTable';
import { getSubscriptionService } from '../../services/subscriptionsServices';
import {
  SubscriptionEditPriceDialog,
  SubscriptionAddExclusionDialog,
  SubscriptionDeleteExclusionDialog,
  SubscriptionAddNoteDialog,
  SubscriptionDeleteNoteDialog,
} from './components/Dialog';
import { useOrigin, useCompany } from '../../hooks';
import styles from './styles.module.css';
import SubscriptionBillingDialog from './SubscriptionBillingDialog';
import PitchbookingUserOnly from '../../helpers/PitchbookingUserOnly';

const SubscriptionView = ({ match }) => {
  const companyInfo = useCompany();
  const { subscriptionId } = match.params;
  const { managerUrl } = useOrigin();

  // Dialog states
  const [dialogs, setDialogs] = useState({
    editPrice: false,
    addExclusion: false,
    addNote: false,
    deleteExclusion: { isOpen: false, id: null },
    deleteNote: { isOpen: false, id: null },
    billingDialog: { isOpen: false, id: null },
  });

  // Fetch subscription data
  const { data: subscription, isLoading, isError } = useQuery({
    queryKey: ['subscription', subscriptionId],
    queryFn: async () => {
      const { data, status } = await getSubscriptionService(companyInfo.id, subscriptionId);
      if (status !== 200) throw new Error('Failed to fetch subscription');
      return data;
    },
    enabled: Boolean(companyInfo.id),
  });

  if (isLoading) return <LoadingSkeletonDataTable />;
  if (isError || !subscription) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h6" color="error">Failed to load subscription</Typography>
        </CardContent>
      </Card>
    );
  }

  const {
    accessRestriction,
    amount,
    taxExempt,
    type,
    status,
    stripePaymentType,
    user,
    stripeSubscriptionId,
  } = subscription;

  const facility = subscription.accessRestriction?.facilities?.filter((x) => x.type !== 'AMENITY')?.[0];
  const amenities = subscription.accessRestriction?.facilities?.filter((x) => x.type === 'AMENITY');

  const timezone = facility?.timezone || 'Europe/London';
  const { stripeId, taxRate, currencySym } = companyInfo;
  const { totalExTax, tax } = priceTaxCalculator(amount / 100, taxExempt, taxRate);
  const globalNotes = subscription.notes.filter((x) => !x.date);

  const getPaymentStatus = () => {
    if (type === 'PAYP') return 'Invoice / Pay on Arrival';
    if (type !== 'STRIPE_SUBSCRIPTION') return '';

    const paymentType = stripePaymentType ? `${stripePaymentType.slice(0, 1).toUpperCase()}${stripePaymentType.slice(1).toLowerCase()}` : null;

    const statusMap = {
      ACTIVE: `${paymentType} Payment Confirmed`,
      INACTIVE: 'Awaiting Payment Confirmation',
      PROCESSING: 'Processing Payment Setup',
    };
    return statusMap[status] || '';
  };

  const canEditPrice = companyInfo?.products?.updateSubscriptionPrice === 'ENABLED'
    && (stripeSubscriptionId || type === 'PAYP')
    && !subscription.nextSubscriptionId;

  return (
    <div style={{ margin: '1rem' }}>
      <Back />

      {/* Main Details Card */}
      <div style={{ marginTop: '1rem' }}>
        <Card>
          <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            {/* Subscription Details */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Subscription Details</Typography>

              <div className={styles.grid}>
                <ListItemText
                  primary={subscription.subscriptionName}
                  secondary="Name"
                />
                <ListItemText
                  primary={moment.weekdays(accessRestriction.weekday)}
                  secondary="Day"
                />
                <ListItemText
                  primary={`${moment(accessRestriction.startTime, 'HH:mm:ss').format('h:mm A')} - ${moment(accessRestriction.endTime, 'HH:mm:ss').add(1, 'seconds').format('h:mm A')}`}
                  secondary="Time"
                />
                <ListItemText
                  primary={generateValidityText(accessRestriction, timezone)}
                  secondary="Validity"
                />
                <ListItemText
                  primary={facility?.name}
                  secondary="Facility"
                />
                <ListItemText
                  primary={status}
                  secondary="Status"
                />
              </div>
            </section>

            <Divider />

            {/* Subscriber Details */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Subscriber</Typography>

              <div className={styles.grid}>
                <ListItemText
                  primary={`${user?.firstName} ${user?.lastName}`}
                  secondary="Name"
                />
                <ListItemText
                  primary={user.email}
                  secondary="Email"
                />
                <ConditionallyVisible condition={user.phone}>
                  <ListItemText
                    primary={`+${user.dialCode} ${user.phone}`}
                    secondary="Phone"
                  />
                </ConditionallyVisible>
              </div>
            </section>

            <Divider />

            {/* Payment Details */}
            <section className={styles.section}>
              <Typography variant="h6" color="secondary">Payment</Typography>

              <div className={styles.grid}>
                <ListItemText
                  primary={`${currencySym}${(amount / 100).toFixed(2)} (${currencySym}${totalExTax.toFixed(2)} + ${currencySym}${tax.toFixed(2)} tax)`}
                  secondary="Price"
                />
                <ListItemText
                  primary={getPaymentStatus()}
                  secondary="Payment Status"
                />
                <ListItemText
                  primary={moment(subscription.createdAt).tz(timezone).format('DD-MMM-YYYY, HH:mm A')}
                  secondary="Created At"
                />
                {subscription.stripeDetails && (
                <>
                  <ListItemText
                    primary={moment.unix(subscription.stripeDetails.nextBilling.unix).tz(timezone).format('DD-MMM-YYYY, HH:mm A')}
                    secondary="Next Payment"
                  />
                </>
                )}

              </div>
            </section>
            <section>
              {subscription.nextSubscriptionId && (
              <>
                <div className="break" />
                <p>
                  <UpdateIcon
                    color="error"
                    style={{
                      alignSelf: 'center',
                      verticalAlign: 'middle',
                      marginRight: '10px',
                    }}
                  />
                  {(() => {
                    const changeDate = moment(subscription.accessRestriction.validTo).add(1, 'week');
                    const formattedDate = changeDate.format('Do MMM YYYY');

                    if (changeDate.isBefore(moment(), 'day')) {
                      return `The price of this subscription changed on ${formattedDate}. `;
                    }
                    if (changeDate.isSame(moment(), 'day')) {
                      return `The price of this subscription changes today, ${formattedDate}. `;
                    }
                    return `The price of this subscription will change on ${formattedDate}. `;
                  })()}
                  <Link to={`/subscriptions/${subscription.nextSubscriptionId}`}>
                    See updated subscription here
                  </Link>
                </p>
              </>
              )}
            </section>

            {/* Addons/Amenities */}
            {(amenities?.length > 0 || subscription.addons?.length > 0) && (
              <>
                <Divider />
                <section className={styles.section}>
                  <Typography variant="h6" color="secondary">Addons</Typography>

                  <div className={styles.grid}>
                    {amenities?.length > 0 && amenities.map((x) => (
                      <ListItemText
                        primary={x.name}
                        secondary="Facility Amenity"
                      />
                    ))}
                    {subscription.addons?.length > 0 && subscription.addons.map((x) => (
                      <ListItemText
                        primary={x.quantity ? `${x.name} x${x.quantity}` : x.name}
                        secondary="Addon"
                      />
                    ))}
                  </div>
                </section>
              </>
            )}

            {/* Global Notes */}
            {globalNotes.length > 0 && (
              <>
                <Divider />
                <section className={styles.section}>
                  <Typography variant="h6" color="secondary">Notes</Typography>

                  {globalNotes.map((note) => (
                    <Typography key={note.id} variant="body2" color="textSecondary">
                      {note.value}
                    </Typography>
                  ))}
                </section>
              </>
            )}

            <Divider />

            {/* Action Buttons */}
            <section className={styles.flexFlexible} style={{ justifyContent: 'space-between', gap: '1rem' }}>
              <ButtonGroup
                variant="outlined"
                color="secondary"
              >
                {canEditPrice && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setDialogs((d) => ({ ...d, editPrice: true }))}
                  >
                    Change Price
                  </Button>
                )}
                {subscription.stripeDetails && (
                  <PitchbookingUserOnly>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setDialogs((x) => ({
                        ...x,
                        billingDialog: {
                          isOpen: true,
                          id: subscription.stripeSubscriptionId,
                          initialTime: subscription.stripeDetails.nextBilling.unix,
                        },
                      }))}
                    >
                      Change Billing Time
                    </Button>
                  </PitchbookingUserOnly>
                )}
              </ButtonGroup>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <Button
                  variant="outlined"
                  component="a"
                  href={`${managerUrl}/users/${user?.id}`}
                >
                  View Customer
                </Button>
                {stripeSubscriptionId && (
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  href={`https://dashboard.stripe.com/${stripeId}/subscriptions/${stripeSubscriptionId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View in Stripe
                </Button>
                )}
              </div>
            </section>
          </CardContent>
        </Card>
      </div>

      {/* Exclusions Card */}
      <div style={{ marginTop: '2rem' }}>
        <div className={styles.tableHeader}>
          <Typography variant="h6" color="secondary">Exclusions</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setDialogs((d) => ({ ...d, addExclusion: true }))}
          >
            Add Exclusion
          </Button>
        </div>

        <MaterialTable
          data={subscription.accessRestriction?.exclusions || []}
          ToolTip={() => <ToolTip contentfulName="exclusionsToolTip" />}
          columns={[
            {
              title: 'Excluded Date',
              field: 'date',
              render: (row) => <Typography>{moment(row.date).format('DD/MM/YYYY')}</Typography>,
            },
            {
              title: 'Created At',
              field: 'createdAt',
              render: (row) => <Typography>{moment(row.createdAt).format('DD/MM/YYYY')}</Typography>,
            },
            {
              title: 'Actions',
              field: 'actions',
              render: (row) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setDialogs((d) => ({
                    ...d,
                    deleteExclusion: { isOpen: true, id: row.id },
                  }))}
                >
                  Delete Exclusion
                </Button>
              ),
            },
          ]}
        />
      </div>

      {/* Notes Card */}
      <div style={{ marginTop: '2rem' }}>
        <div className={styles.tableHeader}>
          <Typography variant="h6" color="secondary">Notes</Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setDialogs((d) => ({ ...d, addNote: true }))}
          >
            Add Note
          </Button>
        </div>

        <MaterialTable
          data={subscription.notes || []}
          ToolTip={() => <ToolTip contentfulName="subscriptionNotesToolTip" />}
          columns={[
            {
              title: 'Date',
              field: 'date',
              render: (row) => <Typography>{row.date ? moment(row.date).format('DD/MM/YYYY') : 'Persistent Note'}</Typography>,
            },
            {
              title: 'Created At',
              field: 'createdAt',
              render: (row) => <Typography>{moment(row.createdAt).format('DD/MM/YYYY')}</Typography>,
            },
            {
              title: 'Note',
              field: 'value',
            },
            {
              title: 'Actions',
              field: 'actions',
              render: (row) => (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setDialogs((d) => ({
                    ...d,
                    deleteNote: { isOpen: true, id: row.id },
                  }))}
                >
                  Delete Note
                </Button>
              ),
            },
          ]}
        />
      </div>

      {/* Orders Table Card */}
      <div style={{ marginTop: '2rem' }}>
        <SubscriptionStripeOrdersTable
          subscription={subscription}
          stripeCompanyId={stripeId}
        />
      </div>

      {/* Dialogs */}
      <SubscriptionEditPriceDialog
        isOpen={dialogs.editPrice}
        subscription={subscription}
        onClose={() => setDialogs((d) => ({ ...d, editPrice: false }))}
      />

      <SubscriptionAddExclusionDialog
        isOpen={dialogs.addExclusion}
        subscription={subscription}
        onClose={() => setDialogs((d) => ({ ...d, addExclusion: false }))}
      />

      <SubscriptionDeleteExclusionDialog
        isOpen={dialogs.deleteExclusion.isOpen}
        subscription={subscription}
        exclusionId={dialogs.deleteExclusion.id}
        onClose={() => setDialogs((d) => ({ ...d, deleteExclusion: { isOpen: false, id: null } }))}
      />

      <SubscriptionAddNoteDialog
        isOpen={dialogs.addNote}
        subscription={subscription}
        onClose={() => setDialogs((d) => ({ ...d, addNote: false }))}
      />

      <SubscriptionDeleteNoteDialog
        isOpen={dialogs.deleteNote.isOpen}
        subscription={subscription}
        noteId={dialogs.deleteNote.id}
        onClose={() => setDialogs((d) => ({ ...d, deleteNote: { isOpen: false, id: null } }))}
      />

      <SubscriptionBillingDialog
        isOpen={dialogs.billingDialog.isOpen}
        subscription={subscription}
        initialTime={dialogs.billingDialog.initialTime}
        onClose={() => setDialogs((d) => ({
          ...d,
          billingDialog: { isOpen: false, id: null, initialTime: null },
        }))}
      />
    </div>
  );
};

SubscriptionView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      subscriptionId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SubscriptionView;
