/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import {
  Button,
  Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ConditionallyVisible from '@pitchbooking-dev/pb-shared/lib/components/conditionallyVisible/conditionallyVisible';
import Alert from '@material-ui/lab/Alert';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import * as actions from '../../reducers/invoicesReducer';
import * as companyActions from '../../reducers/companiesReducer';
import * as userActions from '../../reducers/usersReducer';
import MembersDialog from '../memberships/components/MembersDialog';
import CompanyPaymentMethods from './components/CompanyPaymentMethods';
import ToolTip from '../../components/Tooltip';

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    margin: theme.spacing(2),
    border: 'none',
    padding: theme.spacing(0, 1),
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
    minHeight: '38px',
  },
}))(ToggleButtonGroup);

const PaymentConfigDialog = () => {
  const dispatch = useDispatch();

  const {
    companyInfo,
    stripeAuthorisationMessage,
    tryingStripeAuthorisation,
    memberships,
    taxExemptMemberships,
  } = useSelector((state) => state.companies);
  const { users } = useSelector((state) => state.users);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [invoiceInstructions, setInvoiceInstructions] = useState(null);
  const [subscriptionInstructions, setSubscriptionInstructions] = useState(null);
  const [serviceFeesEnabled, setServiceFeesEnabled] = useState(false);
  const [serviceFees, setServiceFees] = useState(null);
  const [showSubTooltip, setShowSubTooltip] = useState(false);
  const defaultSubInstructionsMessage = `
  Should you wish to cancel a booking on a given week, ${companyInfo?.name} requests that you provide at least 
  ${companyInfo?.adminAccountSettings && companyInfo?.adminAccountSettings.subscriptionCancellation
    ? companyInfo?.adminAccountSettings.subscriptionCancellation
    : '48 hours'} 
  notice. After this time you will be charged for the use of the facility regardless of use.
  Note that this cancellation policy has been set by ${companyInfo?.name} and not by Pitchbooking.
  If you do cancel in time, you will receive a refund for that cancellation from ${companyInfo?.name}.`;

  const {
    stripeId,
    stripeAccepted: stripeConfirmed,
    invoiceAccepted,
    taxExemptUsers,
    payLaterConfigurationSetting,
  } = companyInfo;
  const serviceFeesProductEnabled = companyInfo?.products?.serviceFees === 'ENABLED';
  const [companyPayLaterMinutes, setCompanyPayLaterMinutes] = useState(null);
  const [companyPayLaterAnchor, setCompanyPayLaterAnchor] = useState(companyInfo?.products?.payLater === 'ENABLED' ? 'START_OF_BOOKING' : null);

  const updateInvoiceInstructions = (reservation) => dispatch(
    actions.updateInvoiceInstructions(reservation),
  );

  const getMemberships = () => dispatch(
    companyActions.requestCompanyMembershipRetrieval('INVOICE_MEMBERS_ONLY'),
  );

  const getTaxExemptMemberships = () => dispatch(
    companyActions.requestCompanyMembershipRetrieval('TAX_EXEMPT'),
  );

  const getUsers = () => dispatch(
    userActions.getUsers(),
  );

  const getCompanyInfo = () => dispatch(
    companyActions.getCompanyInfo(),
  );

  const changePaymentPreferenceAction = (preference) => dispatch(
    companyActions.changePaymentPreference(preference),
  );

  const updateCompanyDetails = (details) => dispatch(
    companyActions.updateCompanyDetails(details),
  );

  const changePaymentPreference = async (preference) => {
    changePaymentPreferenceAction(preference);
    setIsLoading(false);
  };

  const handleRequestClose = () => {
    const originalInvoiceInstructions = companyInfo.invoiceInstructions;
    const originalServiceFees = companyInfo.serviceFees;
    if (originalInvoiceInstructions !== invoiceInstructions) {
      updateInvoiceInstructions(invoiceInstructions);
    }
    if (originalServiceFees !== serviceFees) {
      dispatch(companyActions.updateServiceFees(serviceFees));
    }
  };

  const handleToggleServiceFees = (e) => {
    const isChecked = e.target.checked;
    setServiceFeesEnabled(isChecked);

    if (!isChecked) {
      setServiceFees(null);
    } else {
      setServiceFees(companyInfo?.serviceFees || {});
    }
  };

  const handleServiceFeeChange = (newValues) => {
    setServiceFees((prevFees) => ({
      ...prevFees,
      ...newValues,
    }));
  };

  const reload = () => {
    getUsers();
    getMemberships();
    getTaxExemptMemberships();
    getCompanyInfo();
  };

  let invoiceInstructionsSet = true;
  if (companyInfo.invoiceInstructions === '' || companyInfo.invoiceInstructions === null || companyInfo.invoiceInstructions === undefined) {
    invoiceInstructionsSet = false;
  }

  const stripeUrl = process.env.REACT_APP_STRIPE_URL;
  let message = 'Choose your payment configuration options below:';
  let messageStyle = {};

  if (errorMessage !== null) {
    messageStyle = { color: 'RGBA(235, 76, 68, 1.00)' };
    message = errorMessage;
  }

  useEffect(() => {
    if (companyInfo.id) {
      getMemberships();
      getTaxExemptMemberships();
      getUsers();
      setInvoiceInstructions(companyInfo.invoiceInstructions);
      setServiceFeesEnabled(companyInfo?.serviceFees !== null);
      setServiceFees(companyInfo?.serviceFees || {});
    }
  }, [companyInfo.id]);

  useEffect(() => {
    if (payLaterConfigurationSetting?.paymentAnchor) {
      setCompanyPayLaterAnchor(payLaterConfigurationSetting?.paymentAnchor);
    }
    setCompanyPayLaterMinutes(payLaterConfigurationSetting?.minutes);
  }, [payLaterConfigurationSetting]);

  return (
    <div style={{ margin: 40 }}>
      <div>
        <h2>Configure Payment Options</h2>
      </div>

      <CompanyPaymentMethods />
      <div>
        <div style={messageStyle}>
          {message}
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
          }}
        >
          <ConditionallyVisible condition={taxExemptUsers}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 20,
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography variant="h5">Tax Exempt Users</Typography>
                <Typography variant="subtitle1">
                  Users added to this list will not be charged tax and their prices will
                  be reduced.
                </Typography>
              </div>
              <div>
                {taxExemptMemberships[0]
                    && (
                    <MembersDialog
                      members={taxExemptMemberships[0].members}
                      membershipName={taxExemptMemberships[0].name}
                      membershipID={taxExemptMemberships[0].id}
                      selectedMembershipId={null}
                      userList={users && users.map((user) => ({
                        label: `${user.firstName} ${user.lastName} <${user.email}>`,
                        fullName: `${user.firstName} ${user.lastName}`,
                        email: `${user.email}`,
                        id: `${user.id}`,
                      }))}
                    />
                    )}
              </div>
            </div>
          </ConditionallyVisible>
          <ConditionallyVisible condition={companyInfo?.products?.payLater === 'ENABLED'}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 20,
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Pay Later Checkout Option</Typography>
                <ToolTip contentfulName="selfServiceToolTip" />
              </div>
              <StyledToggleButtonGroup
                style={{ marginLeft: 'auto' }}
                size="small"
                disabled={isLoading}
                exclusive
                value={companyInfo?.products?.payLater}
                onChange={(_e, value) => {
                  if (value === null) return;
                  dispatch(companyActions.updateCompanyProduct('payLater', value));
                  dispatch(companyActions.saveCompanyProducts(companyInfo?.id));
                }}
                aria-label="self-service-subscription-configuration"
              >
                <ToggleButton value="ENABLED" aria-label="self service subscription on">
                  On
                </ToggleButton>
                <ToggleButton value="DISABLED" aria-label="self service subscription off">
                  Off
                </ToggleButton>
              </StyledToggleButtonGroup>
            </div>
          </ConditionallyVisible>
          <br />

          <ConditionallyVisible condition={companyInfo?.products?.payLater === 'ENABLED'}>
            <div>
              Pay Later Occurs when a customer selects the Pay Later option at checkout.
              Configure when payment will be taken below. (
              Add + or - minutes to the anchor time to set
              the time when payment will be taken. )
              <br />
              <br />
              <div style={{
                display: 'flex', alignItems: 'center', gap: 20, justifyContent: 'end',
              }}
              >
                <TextField
                  id="companyPayLaterMinutes"
                  value={companyPayLaterMinutes || 0}
                  defaultValue={companyPayLaterMinutes || 0}
                  margin="normal"
                  multiline
                  type="number"
                  variant="outlined"
                  onChange={(event) => setCompanyPayLaterMinutes(event.target.value)}
                  label="+ / - (minutes)"
                />
                <Select
                  value={companyPayLaterAnchor}
                  onChange={(e) => setCompanyPayLaterAnchor(e.target.value)}
                  name="Pay Later Anchor"
                  label="Pay Later Anchor"
                >
                  <option value="START_OF_BOOKING">Start of Booking</option>
                  <option value="END_OF_BOOKING">End of Booking</option>
                  <option value="BOOKING_CREATED">Booking Created</option>
                </Select>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={
                    () => dispatch(companyActions.updateCompanyPayLaterSettings(
                      { minutes: companyPayLaterMinutes, paymentAnchor: companyPayLaterAnchor },
                    ))
                  }
                >
                  Set Payment Anchor
                </Button>
              </div>
            </div>
          </ConditionallyVisible>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5">Self Service Subscriptions</Typography>
              <ToolTip contentfulName="selfServiceToolTip" />
            </div>
            <StyledToggleButtonGroup
              style={{ marginLeft: 'auto' }}
              size="small"
              disabled={isLoading}
              exclusive
              value={companyInfo.selfServiceSubscription}
              onChange={(_e, value) => {
                if (value === null) return;
                updateCompanyDetails(
                  { selfServiceSubscription: value },
                );
                reload();
              }}
              aria-label="self-service-subscription-configuration"
            >
              <ToggleButton value="ON" aria-label="self service subscription on">
                On
              </ToggleButton>
              <ToggleButton value="OFF" aria-label="self service subscription off">
                Off
              </ToggleButton>
              {
                  /* TODO: Disable this for now until it's implemented on booker
                  <ToggleButton
                    value="REQUIRES_APPROVAL"
                    aria-label="self service subscription requires approval"
                  >
                    Requires Approval
                  </ToggleButton>
                  */
                }
            </StyledToggleButtonGroup>
            <ConditionallyVisible condition={invoiceAccepted === 'MEMBERS_ONLY' && memberships && memberships.length > 0}>
              <div>
                {memberships[0]
                    && (
                    <MembersDialog
                      members={memberships[0].members}
                      membershipName={memberships[0].name}
                      membershipID={memberships[0].id}
                      selectedMembershipId={null}
                      userList={users && users.map((user) => ({
                        label: `${user.firstName} ${user.lastName} <${user.email}>`,
                        fullName: `${user.firstName} ${user.lastName}`,
                        email: `${user.email}`,
                        id: `${user.id}`,
                      }))}
                    />
                    )}
              </div>
            </ConditionallyVisible>
          </div>

          {/* Subscription Instructions */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
            marginBottom: 20,
          }}
          >
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            }}
            >
              <Typography
                variant="h5"
                style={{
                  fontWeight: '600', display: 'flex', gap: '0.25rem', alignItems: 'center',
                }}
              >
                Subscription Instructions
                <HelpTwoToneIcon
                  onClick={() => setShowSubTooltip(!showSubTooltip)}
                  style={{ fontSize: '1.5rem', cursor: 'pointer', color: !showSubTooltip ? 'var(--accent)' : '' }}
                />
              </Typography>
            </div>

            {showSubTooltip && (
              <Alert severity="info" style={{ marginTop: '0.5rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.25rem' }}>
                  <Typography variant="body2">
                    Subscription Instructions will be visible when your customers
                    are confirming their Automatic Stripe Subscriptions.
                    These instructions will display at the online
                    checkout and is often used to display cancellation policies,
                    potential pricing updates and any other relevant information.
                  </Typography>

                  <Typography variant="body2" style={{ textDecoration: 'underline', fontWeight: 600 }}>
                    Default Subscription Instructions:
                  </Typography>
                  <Typography variant="caption">
                    {defaultSubInstructionsMessage}
                  </Typography>
                </div>
              </Alert>
            )}

            <TextField
              id="subscriptionInstructions"
              value={subscriptionInstructions}
              defaultValue={companyInfo.subscriptionInstructions}
              margin="normal"
              multiline
              minRows="3"
              type="textarea"
              fullWidth
              variant="outlined"
              onChange={(event) => setSubscriptionInstructions(event.target.value)}
              placeholder="Add custom instructions for customers at subscription checkout.
              Please note if you choose not to add custom instructions, Default instructions will be displayed (The default instructions can be found in the tooltip)."
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => updateCompanyDetails({ subscriptionInstructions })}
              >
                Save
              </Button>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <div>
              <Typography variant="h5">Offline Payment</Typography>
            </div>
            <StyledToggleButtonGroup
              style={{ marginLeft: 'auto' }}
              size="small"
              disabled={isLoading}
              exclusive
              value={invoiceAccepted}
              onChange={(event, value) => changePaymentPreference({ prefType: 'invoiceAccepted', prefVal: value })}
              aria-label="cash-payment-configuration"
            >
              <ToggleButton value="TRUE" aria-label="cash payment on">
                On
              </ToggleButton>
              <ToggleButton value="FALSE" aria-label="cash payment off">
                Off
              </ToggleButton>
              <ToggleButton value="MEMBERS_ONLY" aria-label="cash payment members only">
                Members only
              </ToggleButton>
            </StyledToggleButtonGroup>
            <ConditionallyVisible condition={invoiceAccepted === 'MEMBERS_ONLY' && memberships && memberships.length > 0}>
              <div>
                {memberships[0]
                    && (
                    <MembersDialog
                      members={memberships[0].members}
                      membershipName={memberships[0].name}
                      membershipID={memberships[0].id}
                      selectedMembershipId={null}
                      userList={users && users.map((user) => ({
                        label: `${user.firstName} ${user.lastName} <${user.email}>`,
                        fullName: `${user.firstName} ${user.lastName}`,
                        email: `${user.email}`,
                        id: `${user.id}`,
                      }))}
                    />
                    )}
              </div>
            </ConditionallyVisible>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            <div>
              <Typography variant="h5">Card Payments</Typography>
              {stripeConfirmed
                  && (
                    <Typography variant="subtitle1">
                      Connected with
                      {' '}
                      <a href={`https://dashboard.stripe.com/${stripeId !== null ? stripeId : ''}`} rel="noopener noreferrer" target="_blank">Stripe</a>
                    </Typography>
                  )}
            </div>
            <div style={{ marginLeft: 'auto', marginRight: '16px' }}>
              {stripeConfirmed ? (
                <div />)
                : (
                  <Button
                    id="manager-settings-set-up-stripe"
                    variant="contained"
                    color="primary"
                    style={{ color: '#fff', backgroundColor: '#47FEB4' }}
                    disabled={tryingStripeAuthorisation}
                    href={stripeUrl}
                  >
                    Set Up With Stripe
                  </Button>
                )}
            </div>
          </div>
          <ConditionallyVisible condition={serviceFeesProductEnabled}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: 20,
              marginBottom: 10,
            }}
            >
              <div>
                <Typography variant="h5">Card Service Fees</Typography>
                {stripeConfirmed
                  && (
                  <div>
                    <PBInput
                      id="toggleServiceFees"
                      type="toggle"
                      label="Enable Service Fees"
                      value={serviceFeesEnabled}
                      onChange={handleToggleServiceFees}
                    />
                    {serviceFeesEnabled && (
                      <>
                        <TextField
                          id="ServiceFeeName"
                          value={serviceFees?.name || ''}
                          margin="normal"
                          fullWidth
                          label="Service Fee Name"
                          variant="outlined"
                          onChange={(e) => handleServiceFeeChange({ name: e.target.value })}
                        />
                        <TextField
                          id="ServiceFeePercentage"
                          value={serviceFees?.percentage || ''}
                          margin="normal"
                          fullWidth
                          type="number"
                          inputProps={{ min: 0, max: 100 }}
                          label="Service Fee Percentage"
                          variant="outlined"
                          onChange={(e) => handleServiceFeeChange({ percentage: e.target.value })}
                        />
                        <TextField
                          id="ServiceFeeFixed"
                          value={serviceFees?.fixed || ''}
                          margin="normal"
                          fullWidth
                          type="number"
                          label="Service Fee Fixed Amount"
                          inputProps={{ min: 0 }}
                          variant="outlined"
                          onChange={(e) => handleServiceFeeChange({ fixed: e.target.value })}
                        />
                        <TextField
                          id="ServiceFeeTaxRate"
                          value={serviceFees?.taxRate || ''}
                          margin="normal"
                          type="number"
                          fullWidth
                          label="Service Fee Tax Rate"
                          inputProps={{ min: 0, step: 0.01, max: 1 }}
                          variant="outlined"
                          onChange={(e) => handleServiceFeeChange({ taxRate: e.target.value })}
                        />
                      </>
                    )}
                  </div>
                  )}
              </div>
            </div>
          </ConditionallyVisible>
          <ConditionallyVisible condition={!stripeConfirmed}>
            <Alert
              id="alert"
              severity="warning"
              style={{ width: '100%' }}
            >
              If you already have Stripe set up, it&apos;s worth adding
              another account to your Dashboard specifically for Pitchbooking.
              <br />
              <br />
              To create a new account, click on the name of your current
              {' '}
              <a href="https://dashboard.stripe.com/dashboard" target="_blank" rel="noreferrer">Stripe account</a>
              {' '}
              in the upper-left corner, and select New account.
              <br />
              <br />
              There&apos;s more info here:
              {' '}
              <a href="https://stripe.com/docs/multiple-accounts" target="_blank" rel="noreferrer">https://stripe.com/docs/multiple-accounts</a>
            </Alert>
          </ConditionallyVisible>
          <div style={{
            marginTop: 20,
            marginBottom: 10,
          }}
          >
            <ConditionallyVisible condition={companyInfo.products?.invoicing === 'ENABLED'}>
              <Typography variant="h5">Invoice Instructions</Typography>
              <ConditionallyVisible condition={!invoiceInstructionsSet}>
                <Alert
                  id="alert"
                  severity="warning"
                  style={{ width: '100%' }}
                >
                  No invoice instructions have been set. Set them below to inform customers
                  on how to pay when they receive an invoice.
                </Alert>
              </ConditionallyVisible>
              <TextField
                id="invoiceInstructions"
                value={invoiceInstructions}
                defaultValue={companyInfo.invoiceInstructions}
                margin="normal"
                multiline
                rows="2"
                type="textarea"
                fullWidth
                variant="outlined"
                onChange={(event) => setInvoiceInstructions(event.target.value)}
              />
            </ConditionallyVisible>
          </div>
        </div>
        {stripeAuthorisationMessage && (
        <p>
          {stripeAuthorisationMessage}
        </p>
        )}
      </div>
      <div style={{
        float: 'right',
        marginBottom: 10,
      }}
      >
        <Button variant="contained" color="primary" onClick={handleRequestClose}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default PaymentConfigDialog;
