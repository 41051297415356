/* eslint-disable react/prop-types */
import React from 'react';
import {
  WarningTwoTone, ScheduleTwoTone, Block, CreditCardTwoTone,
} from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { invoiceStatusColors } from '../../components/StatusTag';
import ActionBar from '../../components/ActionBar';

const InvoiceSummaryComponent = ({
  icon: Icon, count, label, combinedTotal, colour,
}) => (
  <Typography
    variant="body1"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      fontWeight: '500',
      color: colour,
      justifyContent: 'space-between',
      margin: '0 1rem',
    }}
  >
    <Icon style={{ fontSize: '2rem' }} />
    {`${count} ${label}: ${combinedTotal}`}
  </Typography>
);

const invoiceData = [
  {
    type: 'UNPAID',
    label: 'Overdue',
    icon: WarningTwoTone,
    colour: invoiceStatusColors.UNPAID.colorScheme.borderColor,
  },
  {
    type: 'PENDING',
    label: 'Pending',
    icon: ScheduleTwoTone,
    colour: invoiceStatusColors.PENDING.colorScheme.borderColor,
  },
  {
    type: 'PAID',
    label: 'Paid',
    icon: CreditCardTwoTone,
    colour: invoiceStatusColors.PAID.colorScheme.borderColor,
  },
  {
    type: 'VOID',
    label: 'Voided',
    icon: Block,
    colour: invoiceStatusColors.VOID.colorScheme.borderColor,
  },
];

const InvoiceSummary = ({ invoices, currencySym }) => {
  const calculateTotal = (filteredInvoices) => filteredInvoices.reduce(
    (acc, i) => acc + i.total, 0,
  );

  if (!invoices || invoices.length === 0) return null;

  return (
    <ActionBar>
      {invoiceData.map(({
        type, label, icon, colour,
      }) => {
        const filteredInvoices = invoices.filter((i) => i.status === type);
        const count = filteredInvoices.length;

        if (count === 0) return null;

        const combinedTotal = calculateTotal(filteredInvoices);
        const formattedTotal = `${currencySym}${combinedTotal.toFixed(2)}`;

        return (
          <InvoiceSummaryComponent
            icon={icon}
            count={count}
            label={label}
            combinedTotal={formattedTotal}
            colour={colour}
          />
        );
      })}
    </ActionBar>
  );
};

export default InvoiceSummary;
