import React from 'react';
import CreditCardTwoToneIcon from '@material-ui/icons/CreditCardTwoTone';
import AccountBalanceWalletTwoToneIcon from '@material-ui/icons/AccountBalanceWalletTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import DvrTwoToneIcon from '@material-ui/icons/DvrTwoTone';
import ReplayTwoToneIcon from '@material-ui/icons/ReplayTwoTone';
import ExtensionTwoToneIcon from '@material-ui/icons/ExtensionTwoTone';
import SportsHandballTwoToneIcon from '@material-ui/icons/SportsHandballTwoTone';
import BarChartTwoTone from '@material-ui/icons/BarChartTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import AccountCircleTwoToneIcon from '@material-ui/icons/AccountCircleTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import TodayTwoToneIcon from '@material-ui/icons/TodayTwoTone';
import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';
import BarChart from '@material-ui/icons/BarChart';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';

import { ReactComponent as ZelleIcon } from './Svg/zelle.svg';

export const paymentBreakdownConstants = {
  TOTAL: {
    color: '#FFC107',
    icon: <BarChart style={{ fontSize: '2rem', color: '#FFC107' }} />,
    label: 'TOTAL',
  },
  CASH: {
    color: '#4CAF50',
    icon: <AccountBalanceWalletTwoToneIcon style={{ fontSize: '2rem', color: '#4CAF50' }} />,
    label: 'Cash',
  },
  CARD: {
    color: '#FFC107',
    icon: <CreditCardTwoToneIcon style={{ fontSize: '2rem', color: '#FFC107' }} />,
    label: 'Card',
  },
  STRIPE: {
    color: '#2196F3',
    icon: <DvrTwoToneIcon style={{ fontSize: '2rem', color: '#2196F3' }} />,
    label: 'Stripe',
  },
  BANKTRANSFER: {
    color: '#673AB7',
    icon: <AccountBalanceTwoToneIcon style={{ fontSize: '2rem', color: '#673AB7' }} />,
    label: 'Bank Transfer',
  },
  STRIPE_SUBSCRIPTION: {
    color: '#3F51B5',
    icon: <ReplayTwoToneIcon style={{ fontSize: '2rem', color: '#3F51B5' }} />,
    label: 'Stripe Subscription',
  },
  PARTIAL: {
    color: '#FF5722',
    icon: <ExtensionTwoToneIcon style={{ fontSize: '2rem', color: '#FF5722' }} />,
    label: 'Partial',
  },
  ZELLE: {
    color: '#5800CA',
    icon: <ZelleIcon style={{ width: '2rem', height: '2rem', color: '#5800CA' }} />,
    label: 'ZELLE',
  },
  PLEI: {
    color: '#16775d',
    icon: <CreditCardTwoToneIcon style={{ width: '2rem', height: '2rem', color: '#16775d' }} />,
    label: 'Plei App',
  },
  OTHER: '#9E9E9E',
};

export const productBreakdownConstants = {
  total: {
    color: '#FFC107',
    icon: <BarChartTwoTone style={{ fontSize: '2rem', color: '#FFC107' }} />,
  },
  facility: {
    color: '#4CAF50',
    icon: <SportsHandballTwoToneIcon style={{ fontSize: '2rem', color: '#4CAF50' }} />,
  },
  event: {
    color: '#FFD180',
    icon: <DvrTwoToneIcon style={{ fontSize: '2rem', color: '#FFD180' }} />,
  },
  membership: {
    color: '#FF5722',
    icon: <AccountCircleTwoToneIcon style={{ fontSize: '2rem', color: '#FF5722' }} />,
  },
  league: {
    color: '#80D8FF',
    icon: <AccountBalanceTwoToneIcon style={{ fontSize: '2rem', color: '#80D8FF' }} />,
  },
  pos: {
    color: '#D33F49',
    icon: <StorefrontTwoToneIcon style={{ fontSize: '2rem', color: '#D33F49' }} />,
    label: 'Point of Sale (POS)',
  },
  invoice: {
    color: '#5BC8AF',
    icon: <ReceiptTwoToneIcon style={{ fontSize: '2rem', color: '#5BC8AF' }} />,
    label: 'Custom Invoice Items',
  },
  servicefee: {
    color: '#B0BEC5',
    icon: <MonetizationOnTwoToneIcon style={{ fontSize: '2rem', color: '#B0BEC5' }} />,
    label: 'Service Fees',
  },
  OTHER: '#9E9E9E',
};

export const subNonSubBreakdownConstants = {
  subscription: {
    color: '#80D8FF',
    icon: <TodayTwoToneIcon style={{ fontSize: '2rem', color: '#80D8FF' }} />,
    label: 'Subscription',
  },
  nonSubscription: {
    color: '#4CAF50',
    icon: <TrendingUpTwoToneIcon style={{ fontSize: '2rem', color: '#4CAF50' }} />,
    label: 'Non-Subscription',
  },
  OTHER: '#9E9E9E',
};
