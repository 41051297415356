/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Chip, Typography } from '@material-ui/core';
import moment from 'moment';
import { getCurrencySymbol } from '@pitchbooking-dev/pb-shared/lib/helpers';

export const AgreementPricing = ({ billing }) => {
  const {
    company, offerTitle, offerExpiresAt, offerNote, offerAmount, amount,
  } = billing;
  const { currency } = company;
  const currencySymbol = getCurrencySymbol(currency) || '£';
  const taxRate = currencySymbol === '£' ? 0.2 : 0;

  const offerValid = billing?.offerExpiresAt
        && moment().isBefore(moment(billing.offerExpiresAt).endOf('day'))
        && billing?.offerAmount
        && billing?.offerAmount < billing?.amount;

  const [offerTimeLeft, setOfferTimeLeft] = useState('');

  const calculateTimeLeft = () => {
    const now = moment();
    const expiration = moment(offerExpiresAt);
    const duration = moment.duration(expiration.diff(now));

    if (duration.asSeconds() <= 0) {
      setOfferTimeLeft('Expired');
    } else {
      setOfferTimeLeft(`Expires in: ${duration.days()}d ${duration.hours()}h ${duration.minutes()}m ${duration.seconds()}s`);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (offerValid) {
      calculateTimeLeft();
      const intervalId = setInterval(calculateTimeLeft, 1000);
      return () => clearInterval(intervalId);
    }
  }, [offerExpiresAt]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
    }}
    >
      {offerValid && (
        <>
          <Chip
            style={{
              color: 'black',
              background: 'rgb(var(--primary))',
              width: 'fit-content',
            }}
            label={(
              <Typography style={{ fontSize: '1rem', fontWeight: 600 }}>
                {`${offerTitle ? `${offerTitle} Offer` : 'Special Offer'} - Limited Time Only`}
              </Typography>
          )}
          />
          <Typography style={{
            fontSize: '1rem', fontWeight: 600, color: 'rgb(75, 85, 99)', marginTop: '0.75rem',
          }}
          >
            {offerTimeLeft}
          </Typography>

          <Typography style={{ fontSize: '1rem', fontWeight: 600, color: 'rgb(75, 85, 99)' }}>
            {`Original price: ${currencySymbol}${amount} ${taxRate > 0 ? '+ VAT per month' : 'per month'}`}
          </Typography>
        </>
      )}

      <Typography style={{ fontSize: '2.5rem', fontWeight: 600 }}>
        {offerValid
          ? `${currencySymbol}${offerAmount?.toFixed(2)} ${taxRate > 0 ? '+ VAT per month' : 'per month'}`
          : `${currencySymbol}${amount?.toFixed(2)} ${taxRate > 0 ? '+ VAT per month' : 'per month'}`}

      </Typography>

      {offerValid && (
        <Typography style={{
          fontSize: '0.8rem', fontWeight: 600, color: 'rgb(75, 85, 99)', fontStyle: 'italic',
        }}
        >
          {offerNote}
        </Typography>
      )}
    </div>
  );
};
