/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@material-ui/core';
import { ShareTwoTone, HighlightOffTwoTone } from '@material-ui/icons';
import PBInput from '@pitchbooking-dev/pb-shared/lib/components/PBInput';
import { useReward } from 'react-rewards';
import {
  ShareToFacebook,
  ShareToLinkedIn,
  ShareToTwitter,
  ShareToWhatsApp,
} from './ShareButtons';

/**
 * ShareModel Component
 *
 * This component is a modal dialog that allows users to share content via various
 * social media platforms (Facebook, Twitter, LinkedIn, WhatsApp) or copy the link
 * to the clipboard. The modal is triggered by clicking a button, and the dialog
 * provides sharing options and a button to copy the link.
 *
 * Props:
 * - buttonText (string): The text displayed on the share button. Defaults to 'Share'.
 * - title (string): The title of the modal. Defaults to 'Share'.
 * - description (string): The description text in the modal. Defaults to a generic message.
 * - facebookUrl (string): URL to share. If not provided, Facebook share button is not displayed.
 * - twitterUrl (string):  URL to share  If not provided, Twitter share button is not displayed.
 * - linkedInUrl (string): URL to share. If not provided, LinkedIn share button is not displayed.
 * - whatsAppUrl (string): URL to share. If not provided,  WhatsApp share button is not displayed.
 * - copyUrl (string): URL to be copied to the clipboard when the "Copy Link" button is clicked.
*
* Features:
 * - Trigger a modal dialog that displays social media share options and a "Copy Link" button.
 * - Display a "Link copied!" message after copying the link to the clipboard.
 * - Customizable button text, modal title, and description.
 * - Dynamic display of social media share buttons depending on the provided URLs.
 */

export const ShareModel = ({
  buttonText,
  title,
  description,
  facebookUrl,
  twitterUrl,
  linkedInUrl,
  whatsAppUrl,
  copyUrl,
}) => {
  const { reward: emojiReward } = useReward('emojiReward', 'emoji', {
    emoji: ['🎉', '🥳'],
    elementCount: 5,
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const handleOpen = () => setIsDialogOpen(true);
  const handleClose = () => setIsDialogOpen(false);
  const [closeColor, setCloseColor] = useState(null);
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <>
      <Button
        variant="outlined"
        style={{ color: 'rgb(var(--primary))', fontWeight: 600 }}
        onClick={handleOpen}
      >
        <div style={{
          display: 'flex', alignContent: 'center', gap: '0.5rem', alignItems: 'center',
        }}
        >
          <ShareTwoTone />
          {buttonText || 'Share'}
        </div>
      </Button>

      <Dialog open={isDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h6">
              {title || 'Share'}
            </Typography>
            <HighlightOffTwoTone
              onClick={handleClose}
              style={{ cursor: 'pointer', color: closeColor || null }}
              onMouseEnter={() => setCloseColor('var(--destructive)')}
              onMouseLeave={() => setCloseColor(null)}
            />
          </div>
        </DialogTitle>

        <DialogContent>
          <Typography variant="body1">
            {description || 'Share on social media or copy the link'}
          </Typography>

          <div style={{
            display: 'flex', gap: '1rem', justifyContent: 'center', margin: '1rem 0',
          }}
          >
            {facebookUrl && <ShareToFacebook facebookUrl={facebookUrl} />}
            {twitterUrl && <ShareToTwitter twitterUrl={twitterUrl} />}
            {linkedInUrl && <ShareToLinkedIn linkedInUrl={linkedInUrl} />}
            {whatsAppUrl && <ShareToWhatsApp whatsAppUrl={whatsAppUrl} />}
          </div>

          {copyUrl && (
            <div style={{
              display: 'flex', gap: '1rem', alignItems: 'center',
            }}
            >
              <span id="emojiReward" />
              <PBInput value={copyUrl} disabled />
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', padding: '1rem',
          }}
          >
            <div style={{ display: 'flex', gap: '1rem' }}>
              {copyUrl && (
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 600, color: 'black', textWrap: 'nowrap' }}
                onClick={() => {
                  navigator.clipboard.writeText(copyUrl);
                  emojiReward();
                  setLinkCopied(true);
                  setTimeout(() => setLinkCopied(false), 3000);
                }}
              >
                Copy Link
              </Button>
              )}
              <Button variant="contained" onClick={handleClose}>
                Done
              </Button>
            </div>

            {linkCopied && (<Typography variant="body2" color="primary">Link copied!</Typography>)}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
