/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import {
  Summary, Benefits, Details, Onboarding, Testimonials,
} from './agreementTabContent';

const tabs = [
  'Summary',
  'Benefits',
  'Details',
  'Onboarding',
  'Testimonials',
];

export const AgreementTabs = ({ billing, isMobile }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [hoverTab, setHoverTab] = useState(null);

  const handleTabContent = (index) => {
    switch (index) {
      case 0:
        return (
          <Summary billing={billing} />
        );
      case 1:
        return (
          <Benefits isMobile={isMobile} />
        );
      case 2:
        return (
          <Details billing={billing} isMobile={isMobile} />
        );
      case 3:
        return (
          <Onboarding isMobile={isMobile} />
        );
      case 4:
        return (
          <Testimonials isMobile={isMobile} />
        );
      default: {
        return (
          <Summary />
        );
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: !isMobile ? 'row' : 'column',
          width: '-webkit-fill-available',
          padding: '0.5rem',
          borderRadius: '0.5rem',
          background: '#e0dde4',
        }}
      >

        <Tabs
          variant="fullWidth"
          style={{
            width: isMobile ? 'auto' : '100%',
            flexDirection: 'column',
          }}
          orientation={!isMobile ? 'horizontal' : 'vertical'}
          // Without this 👇 we get consle errors but with it our styles get overriden
          // value={activeTab}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={tab}
              label={tab}
              style={{
                textAlign: 'center',
                background: activeTab === index ? '#fff' : null,
                color: hoverTab === index ? 'rgb(var(--primary))' : null,
                fontWeight: activeTab === index ? 600 : 'normal',
                borderRadius: '0.5rem',
              }}
              onMouseEnter={() => setHoverTab(index)}
              onMouseLeave={() => setHoverTab(null)}
              onClick={() => setActiveTab(index)}
            />
          ))}

        </Tabs>
      </div>

      <div style={{ padding: '0rem 1rem', background: '#fff', width: '100%' }}>
        {handleTabContent(activeTab)}
      </div>
    </>

  );
};
