/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { bulkUserInvite } from '../../services/companiesServices';
import { useToast } from '../../hooks';
import { OverlayLoader } from '../OverlayLoader';

const validationSchema = Yup.object().shape({
  users: Yup.mixed().required('File is required'),
});

export const BulkInsertUserForm = ({ onSuccess }) => {
  const companyId = useSelector((state) => state.companies.companyInfo.id);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [rejectedUsers, setRejectedUsers] = useState([]);
  const [showRejectedUsers, setShowRejectedUsers] = useState(false);

  return (
    <>
      {isLoading && (<OverlayLoader />)}
      <Formik
        initialValues={{
          users: null,
        }}
        onSubmit={async (values, { resetForm }) => {
          try {
            setIsLoading(true);
            const uploadUsers = await bulkUserInvite(companyId, values, {
              'Content-Type': 'multipart/form-data',
            });
            setIsLoading(false);
            resetForm();

            if (uploadUsers.status === 200) {
              const rejected = [];
              const invalidUsers = uploadUsers.data.invalidEmails;

              if (invalidUsers && invalidUsers.length > 0) {
                rejected.push(...invalidUsers.map((user) => user.email));
              }

              const rejectedResults = uploadUsers.data.results.filter((result) => result.status === 'rejected');
              if (rejectedResults.length > 0) {
                rejected.push(...rejectedResults.map((x) => x.rejectedUser.email));
              }

              // Handle rejected users display or success
              if (rejected.length > 0) {
                setRejectedUsers(rejected);
                setShowRejectedUsers(true);
              } else {
                onSuccess();
                toast.trigger({
                  message: 'Users invited successfully',
                  type: 'success',
                });
              }
            }
          } catch (err) {
            console.error(err);
            toast.trigger({
              message: 'Unable to invite users. Please check your file follows the correct format.',
              type: 'error',
            });
            setIsLoading(false);
            onSuccess(); // close dialog
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          setFieldValue, isSubmitting, values,
        }) => (
          <Form>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
              {showRejectedUsers ? (
                <>
                  <Alert severity="error">
                    <Typography variant="body1">The following users have not been invited:</Typography>
                    <ul>
                      {rejectedUsers.map((userEmail) => (
                        <li>
                          {userEmail}
                        </li>
                      ))}
                    </ul>
                    <Typography variant="body2">
                      Please check the data provided.
                      If the problem persists, please contact your account manager.
                    </Typography>
                  </Alert>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={() => onSuccess()}>
                      Close
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <input
                    id="users"
                    name="users"
                    type="file"
                    onChange={(e) => {
                      if (e.target.files?.length > 0) {
                        setFieldValue('users', e.target.files[0]);
                      }
                    }}
                    accept="text/csv, application/json"
                  />
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" type="submit" disabled={isSubmitting || values.users === null}>
                      Invite Users
                    </Button>
                  </div>
                </>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
