/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { LinkedIn } from '@material-ui/icons';

export const ShareToLinkedIn = ({ linkedInUrl }) => {
  const [hoverColor, setHoverColor] = useState(null);

  const linkedInBaseUrl = 'https://www.linkedin.com/shareArticle';
  const shareUrl = new URL(linkedInBaseUrl);

  const handleShare = () => {
    shareUrl.searchParams.append('url', linkedInUrl); // URL to share

    window.open(
      shareUrl.toString(),
      '_blank',
      'noopener,noreferrer,width=600,height=400',
    );
  };

  return (
    <LinkedIn
      style={{
        cursor: 'pointer',
        color: hoverColor !== null ? hoverColor : null,
        fontSize: '3rem',
      }}
      onMouseEnter={() => setHoverColor('#0077B5')}
      onMouseLeave={() => setHoverColor(null)}
      onClick={handleShare}
    />
  );
};
