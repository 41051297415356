import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AgreementHeader, AgreementPricing, AgreementTabs, PaymentBar,
} from './components';

export const Agreement = ({ billing, billingCreationStatus }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isMobile = useMemo(() => windowWidth < 768, [windowWidth]);

  return (
    <div style={{ display: 'flex', height: '-webkit-fill-available' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: !isMobile ? '-webkit-fill-available' : '100vw',
          alignItems: 'flex-start',
          padding: '2rem',
          borderRadius: '0.75rem',
          border: '1px solid #e0e0e0',
          margin: !isMobile ? '2.5rem 8rem' : '0 0 2rem 0',
          background: '#fff',
          gap: '2rem',
          flex: '1',
        }}
      >
        <AgreementHeader billing={billing} isMobile={isMobile} />

        <AgreementPricing billing={billing} />

        <AgreementTabs billing={billing} isMobile={isMobile} />
      </div>

      <PaymentBar
        billing={billing}
        billingCreationStatus={billingCreationStatus}
        isMobile={isMobile}
      />
    </div>
  );
};

Agreement.propTypes = {
  billing: PropTypes.shape().isRequired,
  billingCreationStatus: PropTypes.shape().isRequired,
};
