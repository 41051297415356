export const CREATE_LEAGUE_REQUESTED = 'CREATE_LEAGUE_REQUESTED';
export const UPDATE_LEAGUE_STORE = 'UPDATE_LEAGUE_STORE';
export const LEAGUES_RETRIEVAL_REQUESTED = 'LEAGUES_RETRIEVAL_REQUESTED';
export const LEAGUES_RETRIEVAL_SUCCEEDED = 'LEAGUES_RETRIEVAL_SUCCEEDED ';
export const LEAGUE_RETRIEVAL_REQUESTED = 'LEAGUE_RETRIEVAL_REQUESTED';
export const LEAGUE_RETRIEVAL_SUCCEEDED = 'LEAGUE_RETRIEVAL_SUCCEEDED';
export const CONFIRM_FIXTURES_REQUESTED = 'CONFIRM_FIXTURES_REQUESTED';
export const LEAGUE_UPDATE_SCORE = 'LEAGUE_UPDATE_SCORE';
export const LEAGUE_SAVE_RESULT = 'LEAGUE_SAVE_RESULT';
export const CONFIRM_FACILITY_LINK_REQUESTED = 'CONFIRM_FACILITY_LINK_REQUESTED';
export const RESET_LEAGUE_USER_INVITE = 'RESET_LEAGUE_USER_INVITE';
export const UPDATE_LEAGUE_USER_INVITE = 'UPDATE_LEAGUE_USER_INVITE';
export const INVITE_USER_TO_LEAGUE = 'INVITE_USER_TO_LEAGUE';
export const ADJUST_TEAM_POINTS = 'ADJUST_TEAM_POINTS';
export const CREATE_NEW_FIXTURE = 'CREATE_NEW_FIXTURE';
export const GET_TEAM_PLAYERS = 'GET_TEAM_PLAYERS';
export const UPDATE_FIXTURE = 'UPDATE_FIXTURE';
export const RESET_FIXTURE = 'RESET_FIXTURE';
export const ADD_GOALSCORER_TO_FIXTURE = 'ADD_GOALSCORER_TO_FIXTURE';
export const SUBMIT_GOALSCORERS = 'SUBMIT_GOALSCORERS';
export const UDPATE_AVAILABLE_FIXTURE_ASSOCIATES = 'UDPATE_AVAILABLE_FIXTURE_ASSOCIATES';
export const UDPATE_SELECTED_ASSOCIATES = 'UDPATE_SELECTED_ASSOCIATES';
export const UDPATE_SELECTED_ASSOCIATE_PRICE = 'UDPATE_SELECTED_ASSOCIATE_PRICE';
export const ASSIGN_ASSOCIATE = 'ASSIGN_ASSOCIATE';
export const ASSIGN_ASSOCIATE_SUCCEEDED = 'ASSIGN_ASSOCIATE_SUCCEEDED';
export const UPDATE_LEAGUE_TEAMS = 'UPDATE_LEAGUE_TEAMS';
export const CANCEL_FIXTURE = 'CANCEL_FIXTURE';
export const REMOVE_FIXTURE_AGENT = 'REMOVE_FIXTURE_AGENT';
export const UPDATE_TEAM_STATUS = 'UPDATE_TEAM_STATUS';
export const DELETE_LEAGUE_TEAM_REQUEST = 'DELETE_LEAGUE_TEAM_REQUEST';
export const DELETE_LEAGUE_REQUEST = 'DELETE_LEAGUE_REQUEST';
export const GET_LEAGUE_FIXTURES_REQUEST = 'GET_LEAGUE_FIXTURES_REQUEST';
export const GET_LEAGUE_FIXTURES_SUCCESS = 'GET_LEAGUE_FIXTURES_SUCCESS';

export const EDIT_LEAGUE_FACILITY = 'EDIT_LEAGUE_FACILITY';
export const UPDATE_LEAGUE_FIXTURE_FACILITY = 'UPDATE_LEAGUE_FIXTURE_FACILITY';

export const IS_LEAGUE_UPDATING = 'IS_LEAGUE_UPDATING';

export const UPDATE_FIXTURE_SCORE = 'UPDATE_FIXTURE_SCORE';

export const initialState = {
  leagueStore: {
    facilityRequired: false,
    facilities: [],
    pointsForWin: 3,
    pointsForDraw: 1,
    pointsForLoss: 0,
  },
  leagues: [],
  league: {
    accessRestrictions: [],
    invitedUser: {
      firstName: '',
      lastName: '',
      email: '',
      teamName: '',
      phone: '',
    },
  },
  fixture: {
    teamA: {
      users: [],
    },
    teamB: {
      users: [],
    },
    goalScorers: [],
  },
  availableAssociates: [],
  selectedAssociates: [],
  selectedAssociatePrice: null,
  assignAssociateSuccess: null,
  editLeagueFacility: null,
  leagueFixture: {},
  isLeagueUpdating: false,
};

export function updateLeagueInviteUserDetails(user) {
  return { type: UPDATE_LEAGUE_USER_INVITE, user };
}

export function submitGoalScorers() {
  return { type: SUBMIT_GOALSCORERS };
}

export function addGoalScorerToFixture(fixtureId, userId, totalGoals, teamId) {
  return {
    type: ADD_GOALSCORER_TO_FIXTURE, fixtureId, userId, totalGoals, teamId,
  };
}

export function getTeamPlayers(fixtureId) {
  return { type: GET_TEAM_PLAYERS, fixtureId };
}

export function updateFixture(fixture) {
  return { type: UPDATE_FIXTURE, fixture };
}
export function resetFixture() {
  return { type: RESET_FIXTURE };
}

export function resetLeagueInviteUserDetails() {
  return { type: RESET_LEAGUE_USER_INVITE };
}

export function confirmPointsAdjustment(teamId, leagueId, points, adjustmentType, reason) {
  return {
    type: ADJUST_TEAM_POINTS, teamId, leagueId, points, adjustmentType, reason,
  };
}

export function createNewFixture(leagueId, round, fixtureType, teamB, teamA) {
  return {
    type: CREATE_NEW_FIXTURE, leagueId, round, fixtureType, teamB, teamA,
  };
}

export function cancelFixture(fixtureId, outcome, teamA, teamB, winner) {
  return {
    type: CANCEL_FIXTURE, fixtureId, outcome, teamA, teamB, winner,
  };
}

export function requestLeagueCreation(values) {
  return { type: CREATE_LEAGUE_REQUESTED, values };
}

export function updateLeagueStore(leagueProperty) {
  return { type: UPDATE_LEAGUE_STORE, leagueProperty };
}

export function getLeagues(status) {
  return { type: LEAGUES_RETRIEVAL_REQUESTED, status };
}
export function getLeague(id) {
  return { type: LEAGUE_RETRIEVAL_REQUESTED, id };
}
export function updateScore(score, team, fixtureId) {
  return {
    type: LEAGUE_UPDATE_SCORE, score, team, fixtureId,
  };
}
export function saveResult(fixtureId, fixtureCost) {
  return {
    type: LEAGUE_SAVE_RESULT, fixtureId, fixtureCost,
  };
}

export function confirmFixtures(games, id) {
  return { type: CONFIRM_FIXTURES_REQUESTED, id, games };
}

export function confirmFacilityLink() {
  return { type: CONFIRM_FACILITY_LINK_REQUESTED };
}

export function leaguesRequestSucceeded(leagues) {
  return { type: LEAGUES_RETRIEVAL_SUCCEEDED, leagues };
}

export function leagueRequestSucceeded(league) {
  return { type: LEAGUE_RETRIEVAL_SUCCEEDED, league };
}

export function inviteUserToLeague() {
  return { type: INVITE_USER_TO_LEAGUE };
}

export function updateAvailableFixtureAssociates(users) {
  return { type: UDPATE_AVAILABLE_FIXTURE_ASSOCIATES, users };
}

export function updateSelectedAssociate(user) {
  return { type: UDPATE_SELECTED_ASSOCIATES, user };
}

export function updateSelectedAssociatePrice(total) {
  return { type: UDPATE_SELECTED_ASSOCIATE_PRICE, total };
}

export function assignAssociate(fixtureId, leagueId, agents) {
  return {
    type: ASSIGN_ASSOCIATE, fixtureId, leagueId, agents,
  };
}
export function removeFixtureAgent(fixtureId, fixtureAgentId) {
  return {
    type: REMOVE_FIXTURE_AGENT, fixtureAgentId, fixtureId,
  };
}

export function assignAssociateSuccess() {
  return { type: ASSIGN_ASSOCIATE_SUCCEEDED };
}

export function updateLeagueFixtureScore(fixtureId, score) {
  return { type: UPDATE_FIXTURE_SCORE, fixtureId, score };
}

function updateFixtureScore(state, score, team, fixtureId) {
  const fixture = state.league.fixtures.find((fixture) => fixture.id === fixtureId);
  fixture[team] = score;
  return state;
}

export function updateLeagueTeams(teams) {
  return { type: UPDATE_LEAGUE_TEAMS, teams };
}

export function updateTeamStatus(state, leagueTeam) {
  const team = state.teams.find((team) => team.league_team.id === leagueTeam.id);
  team.league_team = leagueTeam;

  const teams = [...state.teams.filter((team) => team.league_team.id !== leagueTeam.id), team];
  return { type: UPDATE_TEAM_STATUS, teams };
}

export function editLeagueFacility(facility) {
  return { type: EDIT_LEAGUE_FACILITY, facility };
}

export function updateLeagueFixtureFacility(leagueId, slots, facility) {
  return {
    type: UPDATE_LEAGUE_FIXTURE_FACILITY, leagueId, slots, facility,
  };
}

export function deleteLeagueTeam(leagueId, teamId) {
  return { type: DELETE_LEAGUE_TEAM_REQUEST, leagueId, teamId };
}

export function deleteLeague(leagueId) {
  return { type: DELETE_LEAGUE_REQUEST, leagueId };
}

export function fetchLeagueFixtures(leagueId) {
  return { type: GET_LEAGUE_FIXTURES_REQUEST, leagueId };
}

export function fetchLeagueFixturesSuccess(data) {
  return { type: GET_LEAGUE_FIXTURES_SUCCESS, data };
}

export function isLeagueUpdating(data) {
  return { type: IS_LEAGUE_UPDATING, data };
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LEAGUE_STORE:
      return { ...state, leagueStore: { ...state.leagueStore, ...action.leagueProperty } };
    case UPDATE_FIXTURE:
      return { ...state, fixture: { ...state.fixture, ...action.fixture } };
    case ADD_GOALSCORER_TO_FIXTURE: {
      const goalScorerIndex = state.fixture.goalScorers.findIndex(
        ((obj) => obj.userId === action.userId),
      );
      if (goalScorerIndex > -1) {
        state.fixture.goalScorers.splice(goalScorerIndex, 1);
      }
      return {
        ...state,
        fixture: {
          ...state.fixture,
          goalScorers: [
            ...state.fixture.goalScorers,
            {
              userId: action.userId,
              fixtureId: action.fixtureId,
              totalGoals: action.totalGoals,
              teamId: action.teamId,
            },
          ],
        },
      };
    }
    case RESET_FIXTURE:
      return { ...state, fixture: { ...initialState.fixture } };
    case UPDATE_LEAGUE_USER_INVITE:
      return {
        ...state,
        league: {
          ...state.league,
          invitedUser: {
            ...state.league.invitedUser,
            ...action.user,
          },
        },

      };
    case LEAGUE_UPDATE_SCORE:
      return updateFixtureScore(state, action.score, action.team, action.fixtureId);
    case LEAGUES_RETRIEVAL_SUCCEEDED:
      return { ...state, leagues: action.leagues };
    case LEAGUE_RETRIEVAL_SUCCEEDED:
      return { ...state, league: { ...state.league, ...action.league } };
    case UDPATE_AVAILABLE_FIXTURE_ASSOCIATES:
      return { ...state, availableAssociates: action.users };
    case UDPATE_SELECTED_ASSOCIATES:
      return { ...state, selectedAssociates: [...state.selectedAssociates, action.user] };
    case UDPATE_SELECTED_ASSOCIATE_PRICE:
      return { ...state, selectedAssociatePrice: action.total };
    case ASSIGN_ASSOCIATE:
      return { ...state, assignAssociateSuccess: null };
    case ASSIGN_ASSOCIATE_SUCCEEDED:
      return {
        ...state, selectedAssociate: [], assignAssociateSuccess: true, selectedAssociatePrice: null,
      };
    case UPDATE_LEAGUE_TEAMS:
      return { ...state, league: { ...state.league, teams: action.teams } };
    case UPDATE_TEAM_STATUS:
      return {
        ...state, league: { ...state.league, teams: action.teams },
      };
    case EDIT_LEAGUE_FACILITY:
      return { ...state, editLeagueFacility: action.facility };
    case GET_LEAGUE_FIXTURES_SUCCESS:
      return {
        ...state, leagueFixture: action.data,
      };
    case IS_LEAGUE_UPDATING:
      return {
        ...state, isLeagueUpdating: action.data,
      };
    default:
      return state;
  }
};
