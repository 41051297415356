/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@material-ui/core';
import {
  CreditCard, VideocamRounded, DesktopMacRounded, Telegram, ArrowForwardRounded,
} from '@material-ui/icons';

const nextSteps = [
  { icon: CreditCard, text: 'Complete Payment' },
  { icon: VideocamRounded, text: 'Onboarding Call' },
  { icon: DesktopMacRounded, text: 'System Setup' },
  { icon: Telegram, text: 'Go Live' },
];

export const Onboarding = ({ isMobile }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    width: '-webkit-fill-available',
  }}
  >
    <Typography style={{ fontSize: '1.25rem', fontWeight: 800 }}>
      Next Steps
    </Typography>

    <div
      style={{
        display: 'flex',
        flexDirection: !isMobile ? 'row' : 'column',
        width: '100%',
        gap: '1rem',
        justifyContent: 'space-between',
        alignItems: !isMobile ? 'baseline' : 'center',
      }}
    >

      {nextSteps.map((nextStep, index) => (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '1rem',
              color: '#000',
              flex: 0,
              flexGrow: 1,
            }}
          >
            <div style={{
              display: 'flex',
              alignItems: 'baseline',
              justifyContent: 'center',
              padding: '1rem',
              width: '5rem',
              height: '5rem',
              background: 'rgb(var(--primary))',
              borderRadius: '50%',
            }}
            >
              <nextStep.icon style={{ fontSize: '2.75rem' }} />
            </div>

            <Typography style={{
              fontSize: '1rem', fontWeight: 600, color: '#000', textAlign: 'center',
            }}
            >
              {nextStep.text}
            </Typography>
          </div>
          {index < nextSteps.length - 1 && (
          <ArrowForwardRounded style={{ fontSize: '2rem', color: '#000', transform: !isMobile ? null : 'rotate(90deg)' }} />
          )}
        </>
      ))}
    </div>
  </div>
);
