/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Facebook } from '@material-ui/icons';

export const ShareToFacebook = ({ facebookUrl }) => {
  const [hoverColor, setHoverColor] = useState(null);

  const facebookBaseUrl = 'https://www.facebook.com/sharer/sharer.php';
  const shareUrl = new URL(facebookBaseUrl);

  const handleShare = () => {
    shareUrl.searchParams.append('u', facebookUrl); // URL to share

    window.open(
      shareUrl.toString(),
      '_blank',
      'noopener,noreferrer,width=600,height=400',
    );
  };

  return (
    <Facebook
      style={{
        cursor: 'pointer',
        color: hoverColor !== null ? hoverColor : null,
        fontSize: '3rem',
      }}
      onMouseEnter={() => setHoverColor('#3b5999')}
      onMouseLeave={() => setHoverColor(null)}
      onClick={handleShare}
    />
  );
};
